import React, { useState, useEffect, useContext, useRef, useLayoutEffect } from "react";
import { connect, useSelector } from "react-redux";
import { _useToasts } from "../../../common/_useToasts";
import { AuthContext } from "../../../common/Auth";
import { replaceOrigin } from "../../../util/helper";
import { getEnabledArtworkLocations } from "../../../util/products/productsAPIUtil";
import { useProductArtworkRestrictionState } from "../state/useProductArtworkRestrictionState";
import { useProductConfigState } from "../../../pages/shop-product/state/useProductConfigState";
import { useThemeSettings } from "../../../layouts/state/useThemeSettings";

const AddDecorationportalCondensed = ({
  artworkList,
  artworkListById,
  locationID,
  getDecoPrice,
  setCatalogueCustomRows,
  catalogueCustomRows,
  parentKey,
  artWorkID,
  setShowData,
  showData,
  temp,
  allowCustomDecoration,
  tempDecorationLocations,
  locationName,
  itemsRows,
  searchTextArtwork,
  artworkSetting,
  storeArtwork,
  selectedDecorationsList,
  product,
  decoExist,
  allowOneDec,
  isAdded,
  setIsAdded,
  decorationListOrder,
  setSelectedLocationIndex,
  artworkCategoriesRestriction,
}) => {
  
  const { userType, user, artworksByUserGroup, userGroupsData,settingData } = useContext(AuthContext)
  
  const [findProductArtvariationId, setFindProductArtvariationId] = useState('')
  const [productDesignId, setProductDesignId] = useState('')
  const [flag, setFlag] = useState(false)
  const [tempArtworkListById,setArtworkListById]=useState(artworkListById)
  const [artworkLocations, setArtworkLocations] = useState([]);
  const [showArtwork, setShowArtwork] = useState(false)
  const [artworksFromStore, setArtworksFromStore] = useState(false)
  const {_addToast} = _useToasts();
  const [remainDecoLocation, setRemainDecoLocation] = useState(
    tempDecorationLocations,
    )
  const artworkGlobalIndicator = useSelector((state) => state.productData.artworkGlobalIndicator);
  const [tempArtworkList, setTempArtworkList] = useState(null);
  const artworkListRef = useRef(null);
  const {
    getStatusLocation,
  } =
  useProductArtworkRestrictionState({
    tempArtworkList,
    product,
    selectedDecorationsList,
    locationName,
    artworkLocations,
    artworksByCategories: artworkCategoriesRestriction
  });


  const {
    getSettingStatus
  } = useProductConfigState({product})

  const {
    showAllVariation,
  } = useThemeSettings({ settingData })


  const hideDecorationName = getSettingStatus('hideDecorationName');
  const hideDecorationType = getSettingStatus('hideDecorationType');
  const preventAutoAttach = getSettingStatus('preventAutoAttach');
  const artworkDuplicatesAllowed = getSettingStatus('artworkDuplicatesAllowed');

  useEffect(() => {
    if(tempArtworkList === null) {
      setTempArtworkList(artworkListById)
    }
  }, [artworkListById])
  
  useEffect(() => {
    if ((artworkSetting && artworkGlobalIndicator) && artworkListById.length === 0) {
      setArtworksFromStore(true)
      artworkListById = artworkListById?.concat(artworkList?.filter(al => artworkListById?.every(ali => ali?.id != al?.id)));
    } else {
      if ( !artworkGlobalIndicator && artworkListById.length === 0 ) {
        setArtworksFromStore(true)
        artworkListById = artworkListById?.concat(artworkList?.filter(al=>artworkListById?.every(ali=>ali?.id != al?.id)));
      }
    }
  }, [artworkList,artworkListById, searchTextArtwork, artworkGlobalIndicator])
  
  useEffect(() => {
    let temp = artworkListById.filter((item) => {
      if (item.artworkSource === 'aether' && (!(!!user?.userId) || artworkSetting)) {
        if (allowCustomDecoration || artworkSetting) {
          return item
        }
      } else {
        return item
      }
    })
    temp = temp.filter((item =>item.singleUseOnly == '0'))
    let tempartworkListById = temp.filter((item => item.identity && item.identity.toLowerCase().indexOf(searchTextArtwork.length > 0 ? searchTextArtwork.toLowerCase() : searchTextArtwork) !== -1 || item.designNo && item.designNo.toLowerCase().indexOf(searchTextArtwork.length > 0 ? searchTextArtwork.toLowerCase() : searchTextArtwork) !== -1 || item.designTypeName && item.designTypeName.toLowerCase().indexOf(searchTextArtwork.length > 0 ? searchTextArtwork.toLowerCase() : searchTextArtwork) !== -1));
    if(!!decorationListOrder && decorationListOrder.length > 0 && artworkListById.length > 0) {
      tempartworkListById = tempartworkListById.sort(function(a,b) {
        return decorationListOrder.indexOf(a.id) - decorationListOrder.indexOf(b.id);
      })
    }
    setArtworkListById(tempartworkListById)
  }, [searchTextArtwork, artworkListById])

  useEffect(() => {
    if (artworkListById && artworkListById.length) {
      let locationCount = 0
      let tempartWorkCount = 0
      artworkListById.map((val, key) => {

        if (`${val?.productToDecorationMapping[0]?.location}-${parentKey}` === locationID && val?.productToDecorationMapping[0]?.isRequiredForStore == '1') {
          tempartWorkCount = tempartWorkCount + 1
          val.productToDecorationMapping[0].mapping.map((mapVal, mapkey) => {
            if (mapVal.color === catalogueCustomRows[parentKey][0]['color']) {
              locationCount = locationCount + 1
              setFindProductArtvariationId(mapVal.variationId)
              setProductDesignId(val?.id)
              setFlag(!flag)
            }
          })

          if (locationCount > 1) {
            setShowData('yes')
            setFindProductArtvariationId('')
            setProductDesignId('')
            setFlag(!flag)
          }
        } else {
          setFindProductArtvariationId('')
          setProductDesignId('')
          setShowData('yes')
          setFlag(!flag)
          val.productToDecorationMapping.map((val) => {

            if (`${val.location}-${parentKey}` == locationID && val.isRequiredForStore == '0') {
              tempartWorkCount = tempartWorkCount + 1
              setTimeout(() => {
                if (tempartWorkCount == 1 && !!document.querySelectorAll(".artMain")?.length && document.querySelectorAll(".artMain")?.length === 1 && !isAdded && !preventAutoAttach) {
                  setIsAdded(true)
                  let id = 'artew_' + parentKey + '1'
                  var link = document.getElementById(id);
                  link && link.click()
                }
              }, 2000)
            } else if (`${val.location}-${parentKey}` == locationID && val.isRequiredForStore == '1') {
              setTimeout(() => {
                const requiredDecoElements = document.querySelectorAll(".requiredDeco");
                if (requiredDecoElements.length === 1 && !isAdded && !preventAutoAttach) {
                  setIsAdded(true);
                  const id = `artew_`+ parentKey + '1';
                  const link = document.getElementById(id);
                  link && link.click();
                }
              }, 2000)
            }
          })
        }
      })
    }
  }, [artworkListById, locationID])

  useEffect(() => {
    if (productDesignId && artworkListById) {
      artworkListById &&
        artworkListById.map((artwork, index) => {
          if (artwork.id === productDesignId) {
            handleArtworkselection(artwork, artwork.design?.variation?.[0])
          }
        })
    }
  }, [productDesignId])

  useEffect(() => {
    getArtworkLocations()
  }, [])

  const getArtworkLocations = () => {
    setShowArtwork(false);
    if(artworkLocations.length === 0) {
      getEnabledArtworkLocations(product._id).then(response => {
        setArtworkLocations(response.data);
        setTimeout(() => {
          setShowArtwork(true);
        }, 250);
      }).catch(error => {
          console.log('error', error);
          _addToast('Error while loading data!', {
              appearance: "error",
              autoDismiss: true
          });
      })
    }
  }

  const handleVariationselection = async (vari, artwork) => {
    let dPrice = await getDecoPrice(artwork.id)
    let decoPricedetails = []
    if (dPrice?.length > 0) {
      dPrice.map((priceArray) => {
        if (artwork?.design?.designType == priceArray.decoratorType) {
          decoPricedetails.push(priceArray)
        }
      })
    }

    let tempcatalogueCustomRows = catalogueCustomRows.filter(
      (rowGroupArray, groupkey) => {
        if (groupkey == parentKey) {
          rowGroupArray.filter((rowArray) => {
            rowArray.decorations &&
              rowArray.decorations.map((decoFilter) => {
                if (`${decoFilter.locationId}-${parentKey}` == locationID) {
                  if (decoPricedetails) {
                    decoFilter.decoPricedetails = decoPricedetails
                  }
                  decoFilter.variationUniqID = vari.design_variation_unique_id
                }
                return decoFilter
              })
            return rowArray
          })
        }
        return rowGroupArray
      },
    )
    setCatalogueCustomRows(tempcatalogueCustomRows)
    temp(parentKey, artwork.id)
  }
  
  let artwork_selected = null
  if (artWorkID) {
    artwork_selected =
      artworkListById.length && artworkListById.find((f) => f.id === artWorkID)

    if (artwork_selected?.design && artwork_selected?.design?.variation) {
      if (artwork_selected.design.variation.length == 1) {

        handleVariationselection(artwork_selected.design.variation[0])
      } else {
        artwork_selected.design.variation.map((vari, index) => {
          if (vari.design_variation_unique_id == findProductArtvariationId) {
            handleVariationselection(vari, artwork_selected)
            setShowData('no')
          }
          if (index == artwork_selected.length - 1) {
            if (showData === 'no') {
              setShowData('yes')
            }
          }
        })
      }
    }
  }


  useEffect(() => {
    let newLocation = []
    tempDecorationLocations &&
      tempDecorationLocations.map((val, index) => {
        let artworkSelected = null
        let checkLocation =
        decoExist && decoExist.find((d) => d.locationId === val.id && d.variationUniqID && d.variationUniqID !== "" && d.variationUniqID !== undefined && allowOneDec !== "2")
        if (checkLocation === undefined || checkLocation === '') {
          newLocation.push(val)
        }
      })
    setRemainDecoLocation(newLocation)
  }, [decoExist, tempDecorationLocations, itemsRows])

  const handleArtworkselection = async (artwork, variation) => {
    if (setSelectedLocationIndex) {
      setSelectedLocationIndex(parentKey)
    }
    let dPrice = null
    if (artwork.design && artwork.design.variation) {
      dPrice = await getDecoPrice(artwork.id)
    }
    let decoPricedetails = []
    if (dPrice?.length > 0) {
      dPrice.map((priceArray) => {
        if (artwork?.design?.designType == priceArray.decoratorType) {
          decoPricedetails.push(priceArray)
        }
      })
    }
    let checkArtwork = true
    const location = getCurrentLocation()
    catalogueCustomRows[parentKey].forEach((row) => {
      checkArtwork = row.decorations.filter(decoration => decoration.artworkID === artwork.id && decoration.locationId === location).length > 0
    })
    if(!checkArtwork) {
      let decoration = (artwork.productToDecorationMapping || []).find(val => `${val.location}-${parentKey}` === locationID)
      decoration = decoration ? decoration : ((artwork.artworkLocation === locationName || artwork.artworkLocation === '') ? {
        locationName,
        location } : null)
      if(decoration) {
        const locationIndex = catalogueCustomRows[parentKey][0].decorations?.findIndex((deco) => deco.locationId === decoration.location)
        if(locationIndex < 0) {
          catalogueCustomRows[parentKey][0].decorations.push({
            location: decoration.locationName,
            locationId: decoration.location,
          })
        } else {
          catalogueCustomRows[parentKey][0].decorations[locationIndex] = {
            location: decoration.locationName,
            locationId: decoration.location,
          }
        }
      }
    }

    let tempcatalogueCustomRows = catalogueCustomRows.filter(
      (rowGroupArray, groupkey) => {
        if (groupkey == parentKey) {
          rowGroupArray.filter((rowArray) => {
            rowArray.decorations &&
            rowArray.decorations.map((decoFilter) => {
              if (
                (`${decoFilter.locationId}-${parentKey}` == locationID &&
                  (!decoFilter.artworkID || decoFilter.artworkID == '')) ||
                (`${decoFilter.locationId}-${parentKey}` == locationID &&
                  (decoFilter.artworkID != artwork.id || decoFilter.variationUniqID != variation.variationId))
              ) {
                decoFilter.artworkID = artwork.id
                decoFilter.variationUniqID = null
                if (artwork.design && artwork.design.variation) {
                  let checkLocation = []
                  if (artwork.productToDecorationMapping?.length) {
                    checkLocation = artwork.productToDecorationMapping.filter(val => `${val.location}-${parentKey}` == locationID)
                    if (checkLocation?.length > 0) {
                      decoFilter.supplierDeco = checkLocation?.[checkLocation.length - 1]?.supplierDeco
                      decoFilter.isRequiredForStore = checkLocation?.[checkLocation.length - 1]?.isRequiredForStore
                    }
                  }
                  if (artwork.design.variation.length === 1) {

                    if (decoPricedetails) {
                      decoFilter.decoPricedetails = decoPricedetails
                    }
                    decoFilter.variationUniqID =
                      artwork.design.variation[0].design_variation_unique_id
                    setShowData('no')
                    temp(parentKey, artwork.id, '', decoFilter.locationId)
                  } else {

                    let checkLocation = (artwork.productToDecorationMapping || []).filter(val => `${val.location}-${parentKey}` == locationID)
                    if (checkLocation.length >= 1 && checkLocation?.[checkLocation.length - 1]?.showAllVariations === '0') {
                      let getVariationId = checkLocation[checkLocation.length - 1].mapping.filter((val) => val.variationId === variation.variationId)

                      decoFilter.isRequiredForStore = checkLocation?.[checkLocation.length - 1]?.isRequiredForStore
                      if (getVariationId.length > 0) {
                        if (decoPricedetails) {
                          decoFilter.decoPricedetails = decoPricedetails
                        }
                        decoFilter.variationUniqID =
                          getVariationId[0].variationId
                        setShowData('no')
                        temp(parentKey, artwork.id, '', decoFilter.locationId)
                      }
                    } else {
                      variation = variation ? variation : artwork?.design?.variation[0]
                      let VarDat = !!artwork?.design?.variation && artwork.design.variation.filter(res => res.design_variation_unique_id === variation.variationId)
                      if (decoPricedetails) {
                        decoFilter.decoPricedetails = decoPricedetails
                      }
                      if(VarDat && VarDat.length > 0) {
                        decoFilter.variationUniqID = VarDat[0].design_variation_unique_id
                        setShowData('no')
                        temp(parentKey, artwork.id, locationName)
                      } else if(!!artwork?.design?.variation?.[0]) {
                        decoFilter.variationUniqID = artwork?.design?.variation?.[0]?.design_variation_unique_id
                        temp(parentKey, artwork.id, locationName)
                      }
                    }
                  }
                }
              } return decoFilter
            })
            return rowArray
          })
        }
        return rowGroupArray
      },
    )
    setCatalogueCustomRows(tempcatalogueCustomRows)
    temp(parentKey, artwork.id, '', location)
  }

  useLayoutEffect(() => {
    let timeoutId = null;
    if (artworkListRef.current) {
      timeoutId = setTimeout(() => {
        const requiredDecoElement = artworkListRef.current.querySelector('.requiredDeco');
        if (requiredDecoElement) {
          artworkListRef.current.classList.add('requiredDiv');
        } else {
          artworkListRef.current.classList.remove('requiredDiv');
        }
      }, 2000);
    }
    return () => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
    };
  }, [artworkListRef.current, locationID]);

  const getCurrentLocation = () => {
    const inputString = locationID || '';
    return inputString.replace(/\b([a-zA-Z]*)-[0-9]\b/g, '$1');
  }

  if( remainDecoLocation.length === 0 ) {
    return null
  }
  return (
    <div className="col-md-12">
      <div className="row pr-4">
        {showData === 'yes' && (
          <div className="col-md">
            <b>Select Artwork:</b>
            <div>
              { productDesignId ? (
                // What is the difference between this and the next block?
                // TODO: define with CE to allocate this logic to the correct block
                <div
                  className="artworkList"
                  style={ { display: 'flex', overflowX: 'auto' } }
                  ref={ artworkListRef }
                >
                  { tempArtworkListById.length && showArtwork &&
                    tempArtworkListById.map(( artwork, index ) => {
                      let showAllArtworkVariations = artworksFromStore ? showAllVariation : false;
                      let imgUrls = [];
                      let getCustomArtwotkByUser = true;
                      let displayArtwork = true;
                      let storeItem = storeArtwork.filter(item => item.designId == artwork?.designId);
                      getCustomArtwotkByUser = ( storeItem?.[0]?.userId === user.userId );
                      showAllArtworkVariations = artwork.productToDecorationMapping[0].showAllVariations === '1';
                      let getVariationId = artwork.productToDecorationMapping[0].mapping
                        .filter(val => val.color == catalogueCustomRows[parentKey][0]['color'] || showAllArtworkVariations);
                      if( artworkDuplicatesAllowed === false ) {
                        let isExist = selectedDecorationsList.filter(decoration => decoration.artworkID == artwork.id);
                        if( selectedDecorationsList.length > 0 && isExist.length > 0 ) {
                          displayArtwork = false;
                        }
                      }
                      let isExistLocations = null;
                      if( selectedDecorationsList?.length > 0 ) {
                        isExistLocations = selectedDecorationsList.filter(decoration => (
                          ( decoration?.location === artwork?.artworkLocation ||
                            artwork.productToDecorationMapping.some(res => res.locationName === decoration.location) ) && decoration?.isRequiredForStore === '1'
                        ));
                      }
                      if( displayArtwork === true && getStatusLocation(artwork.id) ) {
                        if( getVariationId.length > 0 ) {
                          getVariationId.forEach(variation => {
                            let imageUrl = artwork.design.variation.find(val => val.design_variation_unique_id === variation.variationId);
                            if( imageUrl ) {
                              imgUrls.push({
                                itemImageThumbnail: imageUrl.itemImageThumbnail?.[0],
                                variationId: variation.variationId
                              })
                            }
                          });
                        } else {
                          const variationsToShow = artwork.design.variation.filter(res => {
                            const variationColor = typeof res.design_variation_color === 'string' ? res.design_variation_color : '';
                            if((variationColor === '' || variationColor.split(',')
                                .map(word => word.toLowerCase().trim())
                                .includes(catalogueCustomRows[parentKey][0]['color'].toLowerCase()) || showAllArtworkVariations )
                              && !!res?.itemImageThumbnail?.[0] ) {
                              return res;
                            }
                          });
                          if( ( !( !!artwork?.artworkLocation ) || artwork?.artworkLocation === locationName ) &&
                            artwork?.design?.variation != "" && artwork.productToDecorationMapping == "" ) {
                            if( variationsToShow && variationsToShow.length > 0 ) {
                              variationsToShow.forEach(variation => {
                                if( storeItem && storeItem.length > 0 && artworkGlobalIndicator ) {
                                  if( storeItem[0].isEnable
                                    && ( getCustomArtwotkByUser
                                      || artworkSetting
                                      || allowCustomDecoration
                                      || artwork.artworkSource !== 'aether'
                                      && !storeItem[0].userId ) ) {
                                    imgUrls.push({
                                      itemImageThumbnail: variation.itemImageThumbnail?.[0],
                                      variationId: variation.design_variation_unique_id
                                    });
                                  }
                                } else if( artwork?.statusId === "5" ) {
                                  imgUrls.push({
                                    itemImageThumbnail: variation.itemImageThumbnail?.[0],
                                    variationId: variation.design_variation_unique_id
                                  });
                                }
                              });
                            } else {
                              if( storeItem && storeItem.length > 0 && artworkGlobalIndicator && artwork?.design?.variation?.length > 0 ) {
                                if( storeItem[0].isEnable
                                  && ( getCustomArtwotkByUser
                                    || artworkSetting
                                    || allowCustomDecoration
                                    || ( artwork.artworkSource !== 'aether' && !storeItem[0].userId ) ) ) {
                                  variationsToShow.forEach(variation => {
                                    imgUrls.push({
                                      itemImageThumbnail: variation.itemImageThumbnail?.[0],
                                      variationId: variation.design_variation_unique_id
                                    });
                                  });
                                }
                              } else if( artwork?.statusId === "5" ) {
                                variationsToShow.forEach(variation => {
                                  imgUrls.push({
                                    itemImageThumbnail: variation.itemImageThumbnail?.[0],
                                    variationId: variation.design_variation_unique_id
                                  });
                                });
                              }
                            }
                          }
                        }
                        let isrequired = false;
                        if( isExistLocations?.[0]?.artworkID == ( artwork?.id || artwork.designId ) && isExistLocations?.[0]?.isRequiredForStore == "1" ) {
                          isrequired = true;
                        }
                        if( imgUrls.length > 0 ) {
                          return (
                            <div className={ `${ isrequired ? "requiredDeco" : "" }` } key={ 'art2' + index }>
                              { ( productDesignId === artwork?.designId || productDesignId === artwork?.id ) &&
                                imgUrls.map(( variation, imgIndex ) => (
                                  <div
                                    className={ 'col-md artworkbox artworkbox-selected decorows_selartwork' }
                                    onClick={ () => {
                                      handleArtworkselection(artwork, variation);
                                    } }
                                    key={ index + variation.variationId}
                                  >
                                    <img
                                      key={ imgIndex + '_' + index + variation.variationId}
                                      src={ replaceOrigin(variation.itemImageThumbnail) }
                                      className="img-fluid mt-2"
                                      alt="No Image"
                                      style={ { maxHeight: '30px' } }
                                    />
                                    <br/>
                                    { !!!hideDecorationType && (
                                      <label style={ {
                                        display: 'inline-block',
                                        fontWeight: 'bold',
                                        wordBreak: 'break-word'
                                      } }>
                                        { artwork.designTypeName }
                                      </label>
                                    ) }
                                  </div>
                                ))
                              }
                            </div>
                          );
                        }
                      }
                    }) }
                </div>
              ) : (
                <div
                  className="artworkList"
                  style={ { display: 'flex', overflowX: 'auto' } }
                  ref={ artworkListRef }
                >
                  { tempArtworkListById.length && showArtwork &&
                    showData === 'yes' && tempArtworkListById.map(( artwork, index ) => {
                      let showAllArtworkVariations = artworksFromStore ? showAllVariation : false;
                      let imgUrls = [];
                      let getCustomArtwotkByUser = true;
                      let displayArtwork = true;
                      let storeItem = storeArtwork.filter(item => item.designId == artwork?.designId);
                      getCustomArtwotkByUser = ( storeItem?.[0]?.userId === user.userId );
                      let checkLocation = artwork.productToDecorationMapping
                        .filter(val => `${ val.location }-${ parentKey }` == locationID);
                      if( artworkDuplicatesAllowed === false ) {
                        let isExist = selectedDecorationsList.filter(decoration => decoration.artworkID == artwork.id);
                        if( selectedDecorationsList.length > 0 && isExist.length > 0 ) {
                          displayArtwork = false;
                        }
                      }
                      let isExistLocations = null;
                      if( selectedDecorationsList?.length > 0 ) {
                        isExistLocations = selectedDecorationsList.filter(decoration => (
                          ( decoration?.location === artwork?.artworkLocation ||
                            artwork.productToDecorationMapping.some(res => res.locationName === decoration.location) ) &&
                          decoration?.isRequiredForStore === '1'
                        ));
                      }
                      if( displayArtwork === true && artwork.availableChoice !== false ) {
                        if( checkLocation.length >= 1 && checkLocation?.[checkLocation.length - 1]?.showAllVariations === '0') {
                          // this is coming from Advance mapping, so no need to verify the user is allowed to see this
                          // artwork
                          let getVariationId = checkLocation[checkLocation.length - 1].mapping
                            .filter(val => val.color === catalogueCustomRows[parentKey][0]['color']);
                          if( getVariationId.length > 0 ) {
                            getVariationId.forEach(variation => {
                              let variationImage = artwork.design.variation.find(val => val.design_variation_unique_id === variation.variationId);
                              if( variationImage ) {
                                imgUrls.push({
                                  itemImageThumbnail: variationImage.itemImageThumbnail?.[0],
                                  variationId: variation.variationId
                                });
                              }
                            });
                          }
                        } else {
                          // this is coming from aether, need to verify if the user is allowed to see this artwork
                          // based on category, user-gorup,and general assignment
                          if( checkLocation?.[checkLocation.length - 1] ) {
                            showAllArtworkVariations = checkLocation[checkLocation.length - 1].showAllVariations === '1';
                          }
                          const variationsToShow = artwork.design.variation.filter(res => {
                            const variationColor = typeof res.design_variation_color === 'string' ? res.design_variation_color : '';
                            if((variationColor === '' || variationColor.split(',')
                                .map(word => word.toLowerCase().trim())
                                .includes(catalogueCustomRows[parentKey][0]['color'].toLowerCase()) || showAllArtworkVariations )
                              && !!res?.itemImageThumbnail?.[0] ) {
                              return res;
                            }
                          });
                          if( ( !( !!artwork?.artworkLocation ) || artwork?.artworkLocation === locationName ) &&
                            artwork?.design?.variation != "" &&
                            ( artwork.productToDecorationMapping == "" || artwork.productToDecorationMapping.length === 0  || showAllArtworkVariations) ) {
                            if( variationsToShow && variationsToShow.length > 0 ) {
                              variationsToShow.forEach(variation => {
                                if( storeItem && storeItem.length > 0 && artworkGlobalIndicator ) {
                                  if( storeItem[0].isEnable
                                    && ( getCustomArtwotkByUser
                                      || artworkSetting
                                      || allowCustomDecoration
                                      || artwork.artworkSource !== 'aether'
                                      && !storeItem[0].userId ) ) {
                                    imgUrls.push({
                                      itemImageThumbnail: variation.itemImageThumbnail?.[0],
                                      variationId: variation.design_variation_unique_id
                                    });
                                  }
                                } else if( artwork?.statusId === "5" ) {
                                  imgUrls.push({
                                    itemImageThumbnail: variation.itemImageThumbnail?.[0],
                                    variationId: variation.design_variation_unique_id
                                  });
                                }
                              });
                            } else if( storeItem && storeItem.length > 0 && artworkGlobalIndicator && artwork?.design?.variation?.length > 0 ) {
                              if( storeItem[0].isEnable
                                && ( getCustomArtwotkByUser
                                  || artworkSetting
                                  || allowCustomDecoration
                                  || ( artwork.artworkSource !== 'aether' && !storeItem[0].userId ) ) ) {
                                variationsToShow.forEach(variation => {
                                  imgUrls.push({
                                    itemImageThumbnail: variation.itemImageThumbnail?.[0],
                                    variationId: variation.design_variation_unique_id
                                  });
                                });
                              }
                            } else if( artwork?.statusId === "5" ) {
                              variationsToShow.forEach(variation => {
                                imgUrls.push({
                                  itemImageThumbnail: variation.itemImageThumbnail?.[0],
                                  variationId: variation.design_variation_unique_id
                                });
                              });
                            }
                          }
                        }
                        let customeIndex = 0;
                        let isrequired = false;
                        if( isExistLocations?.[0]?.artworkID == artwork?.id && isExistLocations?.[0]?.isRequiredForStore == "1" ) {
                          isrequired = true;
                        }
                        if( imgUrls.length > 0 && getStatusLocation(artwork.id) ) {
                          return imgUrls.map(( variation, imgIndex ) => (
                            <div className={ `${ isrequired ? "artMain requiredDeco" : "artMain" }` }
                                 key={ 'artew_' + parentKey + index + '_' + imgIndex }
                                 findparent={ `parent${ parentKey }` }>
                              <div
                                className={ 'col-md artworkbox decorows_selartwork' }
                                onClick={ () => {
                                  handleArtworkselection(artwork, variation);
                                } }
                                key={ index + '_' + imgIndex }
                              >
                                <img
                                  id={ 'artew_' + parentKey + customeIndex }
                                  src={ replaceOrigin(variation.itemImageThumbnail) }
                                  className="img-fluid mt-1"
                                  alt="No Image"
                                  style={ { maxHeight: '30px' } }
                                /><br/>
                                { !!!hideDecorationName &&
                                  <label style={ { display: 'inline-block', wordBreak: 'break-word' } }>
                                    &nbsp;{ artwork.identity }
                                  </label> }
                              </div>
                            </div>
                          ));
                        }
                      }
                    }) }
                </div>
              ) }
            </div>
          </div>
        ) }
      </div>
    </div>
  )
}

const mapStateToProps = ( state ) => {
  return {
    artworkList: state.productData.artworkList,
    artworkListById: state.productData.artworkListById,
    artworkCategoriesRestriction: state.productData.artworkCategoriesRestriction,
  }
}
export default connect(mapStateToProps)(AddDecorationportalCondensed);


import PropTypes from "prop-types";
import React, { useContext, useEffect, useState } from "react";
import useElementSize from "../../util/custom-hooks/useElementSize";
import { LightgalleryProvider, LightgalleryItem } from "react-lightgallery";
import { productImageExt, productImageUrl } from "../../util/helper";
import {AuthContext} from '../../common/Auth'
import { fetchImageSize, getImageJustification } from "../../wrappers/product/util/getDimensions";
const ProductImageFixed = ({ product,
  selectedDecorations,
  artworkList, artworkListById, selectedColor, setSelectedColor, selectedImage, singleSelectedDecorations, setSelectedThumbImgColor, selectedThumbImgColor,
  showDecoration
}) => {

  const [imgW, imgH] = useElementSize(
    '.product-fixed-image .containerimg img',
  )
  const [imageW, setImageW] = useState(imgW)
  const [imageH, setImageH] = useState(imgH)
  const [DefaultProductBackgroundColor, setDefaultProductBackgroundColor] = useState()
  const [overlayImgWidth, setOverlayImgWidth] = useState(0);
  const [overlayImgHeight, setOverlayImgHeight] = useState(0);
  const [overlayImgTop, setOverlayImgTop] = useState(0);
  const [overlayImgLeft, setOverlayImgLeft] = useState(0);
	const {
		settingData
	  } = useContext(AuthContext)

	useEffect(() => {
		if (settingData?.length > 0) {
		  settingData.map((i) => {
			if (i.page === 'General') {
			  if (!!i?.values?.DefaultProductBackgroundColor) {
				setDefaultProductBackgroundColor(i?.values?.DefaultProductBackgroundColor);
			  }
			}
		  })
		}
	}, [settingData])

  useEffect(() => {
		setTimeout(() => {
		  const element = document.querySelectorAll('.product-fixed-image .containerimg img');
		  setImageW(element[0]?.offsetWidth)
		  setImageH(element[0]?.offsetHeight)
		}, 1000);
	  },[selectedDecorations]);

	useEffect(() => {
		setTimeout(() => {
			const element = document.querySelectorAll('.product-fixed-image .containerimg img');
			setImageW(element[0]?.offsetWidth)
			setImageH(element[0]?.offsetHeight)
		}, 500);
	},[imgW, imgH]);

  let image = null;
  let defaultColor = ""
  let temp = product?.WebstoreProductPartArray.filter((val) => val.image===selectedImage && val?.isSwapImage,)
		 if (temp.length > 0) {
		   if (product?.WebstoreProductPartArray) {
			   product.WebstoreProductPartArray.map((single, index) => {
				if (image == null && selectedImage === single.image) {
					image = single.image
				  }
			   })
		   }
		 }else{
			product.MediaContent.map(mediaArray => {
				if (image == null && selectedImage === mediaArray.url) {
				  image = mediaArray.url
          if(mediaArray?.isNewLogo == "1" && !!mediaArray?.processedImage) {
            image = mediaArray.processedImage
          }
				}
			  })
		 }

  
  if (image == null || image == "") {
    if (product.MediaContent && product.MediaContent.length > 0) {
      image = product.MediaContent[0].url
      defaultColor = product.MediaContent[0].color
    }
  }
  if (selectedColor == "" || selectedColor == null) {
    selectedColor = defaultColor;
  }
  const getReducePer = (width, param) => {
    let reducePer = 100;
    while (((Number(width) * reducePer) / 100) > param) {
      reducePer = reducePer - 1;
    }
    return reducePer;
  }

  useEffect(() => {
		setTimeout(() => {
			const element = document.querySelectorAll('.product-fixed-image .containerimg img');
			if(element.length > 0) {
				setImageW(element[0]?.offsetWidth)
				if(element[0]?.offsetWidth <= 451 && element[0]?.offsetWidth > 0) {
					const element1 = document.querySelectorAll('.product-fixed-image .containerimg');
					if(element1.length > 0) {
						element1[0].style.width = `${element[0]?.offsetWidth}px`
					}
				}
			}
		}, 100);
	},[singleSelectedDecorations]);

  let singlelogoDetails = {}
  let findlogoinfo = ''
  
  if (singleSelectedDecorations != "" && singleSelectedDecorations != null && singleSelectedDecorations != "null" && singleSelectedDecorations != undefined) {
    for (let i = 0; i < product?.LogoInfo.length; i++) {
      const logoInfoData = product?.LogoInfo[i]
      if (logoInfoData.color === selectedColor) {
        if (logoInfoData.image === selectedImage && logoInfoData.location == singleSelectedDecorations.location) {
          findlogoinfo = logoInfoData
          break;
        } else if (logoInfoData.location == singleSelectedDecorations.location) {
          findlogoinfo = logoInfoData
          break;
        } else if (logoInfoData.image === selectedImage) {
          let extra_logo = logoInfoData.extra_logo.filter(val => val.location == singleSelectedDecorations.location)

          if (extra_logo.length > 0) {

            findlogoinfo = extra_logo
            findlogoinfo[0].image = logoInfoData.image
            findlogoinfo[0].color = logoInfoData.color
            findlogoinfo[0].ih = logoInfoData.ih
            findlogoinfo[0].iw = logoInfoData.iw
            findlogoinfo[0].location = extra_logo[0].location
            findlogoinfo[0].h = extra_logo[0].h

            findlogoinfo[0].w = extra_logo[0].w
            findlogoinfo[0].x = extra_logo[0].x
            findlogoinfo[0].y = extra_logo[0].y
            if (logoInfoData?.isNewLogo == "1" && !!logoInfoData?.processedImage) {
              findlogoinfo[0].processedImageWidth = logoInfoData?.processedImageWidth
              findlogoinfo[0].processedImageHeight = logoInfoData?.processedImageHeight
              findlogoinfo[0].isNewLogo = logoInfoData?.isNewLogo
              findlogoinfo[0].processedImage = logoInfoData?.processedImage
            }
            break;
          }
        }
      }
    }
  }
  if (findlogoinfo !== '' && findlogoinfo?.length) {
    singlelogoDetails = findlogoinfo[0]
  } else if (findlogoinfo !== '') {
    singlelogoDetails = findlogoinfo
  }
  let imgWidth = singlelogoDetails.iw
  let imgHeight = singlelogoDetails.ih
  let singleX = singlelogoDetails?.x
  let singleY = singlelogoDetails?.y
  let singleW = singlelogoDetails?.w
  let singleH = singlelogoDetails?.h
  if(singlelogoDetails?.isNewLogo == "1" && !!singlelogoDetails?.processedImage) {
    imgWidth = singlelogoDetails?.processedImageWidth
    imgHeight = singlelogoDetails?.processedImageHeight
    singleX = singlelogoDetails?.newX
    singleY = singlelogoDetails?.newY
    singleW = singlelogoDetails?.newW
    singleH = singlelogoDetails?.newH
  }

  if(imgWidth > 900 || imgHeight > 1100){
    var ratio = Math.min(800 / imgWidth, 800 / imgHeight);

    imgWidth = imgWidth * ratio
    imgHeight = imgHeight * ratio
  }

  let reducePer = getReducePer(imgHeight, imageH)
  // imgWidth = (imgWidth * reducePer) / 100
  // imgHeight = (imgHeight * reducePer) / 100

  //artwork box left top position set set
  let positionSetLeft = (imageW * singleX) / imgWidth
  let positionSetTop = (imageH * singleY) / imgHeight
  //artwork box width/height set
  let positionSetBoxWidth = (imageW * singleW) / imgWidth
  let positionSetBoxHeight = (imageH * singleH) / imgHeight

  const photoZoom = async (zmImg='') => {
    const node = document.querySelector(".single-image");
		let zoomPictureNode = document.getElementsByClassName("lg-img-wrap")
		if(!!zoomPictureNode && !!zoomPictureNode?.[0] && !!DefaultProductBackgroundColor && zmImg !== '')
			zoomPictureNode[0].classList.add(zmImg)

      if (node) {
        const clone = node.cloneNode(true);
        zoomPictureNode[0].classList.add('lg-img-zoom')
        if (zoomPictureNode) {
          zoomPictureNode[0].appendChild(clone);
        }
        var fourChildNode = zoomPictureNode[0].querySelector('.containerimg');
        if(!!fourChildNode) {				
          fourChildNode.style.maxWidth = 'unset'
          fourChildNode.style.margin = 'unset'
          fourChildNode.style.width = 'unset'
        }
        let fourChildNode0 = zoomPictureNode[0].querySelector('.containerimg .react_lightgallery_item');
        let fourChildNode1 = zoomPictureNode[0].querySelector('.containerimg .react_lightgallery_item .img-fluid');
        console.log('fourChildNode1: ', fourChildNode1?.width);
        if (!!fourChildNode1 && fourChildNode1?.width > 2000) {
          fourChildNode1.style.height = 'auto'
          fourChildNode0.style.display = 'flex'
          fourChildNode0.style.alignItems = 'center'
          fourChildNode0.style.justifyContent = 'center'
        }
      }
  
      //get outerbox
      let getSingleImage = singlelogoDetails
      let getOuterBOx = document.querySelector('.lg-img-wrap .react_lightgallery_item')
      if(!!getOuterBOx)
      {
        let imgWidthZoom = parseFloat(getSingleImage?.iw) || 0;
        let imgHeightZoom = parseFloat(getSingleImage?.ih) || 0;
        let singleZoomX = parseFloat(getSingleImage?.x) || 0;
        let singleZoomY = parseFloat(getSingleImage?.y) || 0;
        let singleZoomW = parseFloat(getSingleImage?.w) || 0;
        let singleZoomH = parseFloat(getSingleImage?.h) || 0;

        if(getSingleImage?.isNewLogo === "1" && !!getSingleImage?.processedImage) {
          imgWidthZoom = getSingleImage?.processedImageWidth
          imgHeightZoom = getSingleImage?.processedImageHeight
          singleZoomX = getSingleImage?.newX
          singleZoomY = getSingleImage?.newY
          singleZoomW = getSingleImage?.newW
          singleZoomH = getSingleImage?.newH
          image = getSingleImage?.processedImage

        }

      const { width, height } = await fetchImageSize(getSingleImage.image);

      let maxValue = Number.MIN_VALUE;
      let ratio = 1;
      let newX = getSingleImage.x;
      let newY= getSingleImage.y;
      let newW = getSingleImage.w;
      let newH = getSingleImage.h;

      let newIw = width, newIh = height;
      if(height > 800 || width > 800) {

        maxValue = Math.max(width, height);
        if(maxValue === height) {
          ratio = 800/height
          newIh = 800;
          newIw = ratio*width;
        }else{
          ratio = 800/width;
          newIw = 800;
          newIh = height*ratio;
        }
      }
      if(ratio !== 1){
        newW = (getSingleImage.w*100)/newIw;
        newX = (getSingleImage.x*100)/newIw;
        newY = (getSingleImage.y*100)/newIh;
        newH = (getSingleImage.h*100)/newIh;
      } else{
        newW = (getSingleImage.w*100)/width;
        newX = (getSingleImage.x*100)/width;
        newY = (getSingleImage.y*100)/height;
        newH = (getSingleImage.h*100)/height;
      }
      const imageJustify = getImageJustification(getSingleImage);
        let overlayImag = document.querySelectorAll(".lg-img-wrap .overlayimg")
        const css = '.overlayimg:hover{  transform: scale(1.5); }';
        const style = document.createElement('style');
        if (style.sheet) {
          style.sheet.cssText = css;
        } else {
          style.appendChild(document.createTextNode(css));
        }
        for (let i = 0; i < overlayImag.length; i++) {
          overlayImag[i].style.left = newX + "%"
          overlayImag[i].style.top = newY + "%"
          overlayImag[i].style.width = newW + "%"
          overlayImag[i].style.height = newH + "%"
          overlayImag[i].style.transition = 'transform 0.3s';
          setOverlayImgTop(newX)
          setOverlayImgLeft(newX)
          setOverlayImgWidth(newW );
          setOverlayImgHeight(newH);
          overlayImag[i].appendChild(style)
        }
    
        //add width height to artwork
    
        let overlayImag2 = document.querySelectorAll(".lg-img-wrap .overlayimg .img-fluid")
        for (let i = 0; i < overlayImag2.length; i++) {
          overlayImag2[i].style.removeProperty('max-width');
          overlayImag2[i].style.removeProperty('max-height');
          overlayImag2[i].style.left = overlayImgLeft;
          overlayImag2[i].style.top = overlayImgTop;
          overlayImag2[i].style.width = '100%';
          overlayImag2[i].style.height = '100%';
          overlayImag2[i].style.objectFit = 'contain';
          overlayImag2[i].style.objectPosition = imageJustify;
        }
      }
      let getOuterBOxCheck2 = document.querySelector('.lg-img-wrap .lightGal')

      if(!getOuterBOxCheck2) {
        return
      }
      let zoomInBtn = document.getElementById('lg-zoom-in');
      let zoomOutBtn = document.getElementById('lg-zoom-out');

      let scaleItem = 1

      zoomInBtn.addEventListener('click', function (event) {
        getOuterBOxCheck2.style.transform = `scale(${scaleItem + 0.5}) translateX(-50%)`
        scaleItem = scaleItem + 0.5
      });
      zoomOutBtn.addEventListener('click', function (event) {
        getOuterBOxCheck2.style.transform = `scale(${scaleItem - 0.5}) translateX(-50%)`
        scaleItem = scaleItem - 0.5
      });
	}

  return (
    <div className="product-large-image-wrapper">
      {product.discount || product.new ? (
        <div className="product-img-badges">
          {product.discount ? (
            <span className="pink">-{product.discount}%</span>
          ) : (
            ''
          )}
          {product.new ? <span className="blue">New</span> : ''}
        </div>
      ) : (
        ''
      )}

      <div className="product-fixed-image">
        <div>
          <div class={`${productImageExt(image) ? 'product-img-bg' : ''} single-image lightGal`}>
            <div className="containerimg" style={{maxWidth:'451px', margin:'0px auto'}}>
              <LightgalleryProvider onAfterOpen={() => photoZoom(productImageExt(image) ? 'product-img-bg' : '')}>
                <LightgalleryItem
                  galleryClassName=" "
                  group="any"
                  src={productImageUrl(image)}
                >
                  {image && image !== '' && image != null ? (
                    <img
                      src={productImageUrl(image)}
                      alt=""
                      className="img-fluid"
                    />
                  ) : (
                    ''
                  )}
                  {showDecoration && singlelogoDetails && Object.keys(singlelogoDetails).length > 0 &&
                    selectedDecorations !== "" && selectedDecorations != null && artworkListById?.length > 0 &&
                    selectedDecorations.length > 0 && selectedDecorations.map((li, key) => {
                    const imageJustifyProduct = getImageJustification(singlelogoDetails);
                    let artwork_selected = null
                    if( li?.artworkID !== '' && li?.artworkID !== undefined ) {
                      const idToSearch = singleSelectedDecorations ? singleSelectedDecorations.artworkID : li.artworkID
                      artwork_selected = artworkListById?.find(
                        ( artworkArray ) => artworkArray.id === idToSearch,
                      )
                    }
                    let rowVariation = null
                    if( artwork_selected?.design?.variation?.length > 0 ) {
                      rowVariation = artwork_selected?.design.variation.find(
                        ( variationArray ) =>
                          variationArray.design_variation_unique_id ===
                          li.variationUniqID,
                      )
                    }
                    if(rowVariation && singleSelectedDecorations?.location === li.location) {
                      return (
                        <div
                          className="overlayimg"
                          key={key}
                          style={{
                            transitionDelay: 'none !important',
                            top: positionSetTop + 'px',
                            left: positionSetLeft + 'px',
                            width: '100%',
                            height: '100%',
                          }}
                        >
                          {
                          rowVariation && rowVariation?.itemImageThumbnail && rowVariation.itemImageThumbnail[0] ?
                            <img
                            alt=""
                              className="img-fluid"
                              src={
                                rowVariation?.itemImageThumbnail
                                  ? productImageUrl(
                                    rowVariation.itemImageThumbnail[0],
                                  )
                                  : ''
                              }

                              // style={{ maxWidth: wid + 'px' }}
                              style={{
                                top: positionSetTop + 'px',
                                left: positionSetLeft + 'px',
                                objectPosition: imageJustifyProduct,
                                width: '100%',
                                height: '100%',
                                maxWidth: positionSetBoxWidth + 'px',
                                maxHeight: positionSetBoxHeight + 'px',
                              }}
                            />
                            :
                            <img className="img-fluid"
                              src={rowVariation && rowVariation?.itemImageThumbnail != "" ? productImageUrl(rowVariation) : productImageUrl(rowVariation)}
                              alt=""

                              style={{
                                top: positionSetTop + 'px',
                                left: positionSetLeft + 'px',
                                objectPosition: imageJustifyProduct,
                                width: '100%',
                                height: '100%',
                                maxWidth: positionSetBoxWidth + 'px',
                                maxHeight: positionSetBoxHeight + 'px',
                              }}
                            />
                        }
                        </div>
                      )
                    }})}

                </LightgalleryItem>
              </LightgalleryProvider>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
};

ProductImageFixed.propTypes = {
  product: PropTypes.object
};

export default ProductImageFixed;

import React, { Fragment, useState, useEffect, useContext, useRef, useLayoutEffect } from "react";
import { connect, useSelector } from "react-redux";
import { AuthContext } from "../../../common/Auth"
import { replaceOrigin } from "../../../util/helper";
import { useProductArtworkRestrictionState } from "../state/useProductArtworkRestrictionState";
import { useProductConfigState } from "../../../pages/shop-product/state/useProductConfigState";
import { useThemeSettings } from "../../../layouts/state/useThemeSettings";
const AddDecoration = ({ artworkList,
    setAddnewart,
    selectedProductArtwork,
    setSelectedProductArtwork,
    addDecoration,
    selectedProductLocation,
    artworkListById,
    selectedColor,
    selectedProductColor,
    locationName,
	  searchTextArtwork,
    artworkSetting,
    storeArtwork,
    decorationLocations,
    ThemeFile,
	  allowCustomDecoration,
    selectedDecorationsList,
    locationID,
    tempDecorationLocations,
    product,
    artworkLocations,
    allowOneDec,
    isAdded,
    setIsAdded,
    decorationListOrder,
    artworkCategoriesRestriction,
}) => {
    let getLocationName = decorationLocations && decorationLocations.filter(item => item.id === selectedProductLocation)

    const { storeDetails, userType, user, artworksByUserGroup, userGroupsData, settingData} = useContext(AuthContext)
    const [productDesignId, setProductDesignId] = useState('')
    const [artwork_selected, setArtwork_selected] = useState(null)
    const [findProductArtvariationId, setFindProductArtvariationId] = useState('')
    const [tempArtworkListById,setArtworkListById]=useState(artworkListById)
    const [showArtwork, setShowArtwork] = useState(true)
    const [artworksFromStore, setArtworksFromStore] = useState(false)

    const [remainDecoLocation, setRemainDecoLocation] = useState(
          tempDecorationLocations,
          )
    
    const artworkGlobalIndicator = useSelector((state) => state.productData.artworkGlobalIndicator);
    const [tempArtworkList, setTempArtworkList] = useState(null);
    const artworkListRef = useRef(null);
    const {
        getStatusLocation,
    } =
    useProductArtworkRestrictionState({
        tempArtworkList,
        product,
        selectedDecorationsList,
        locationName: getLocationName?.[0]?.locationName,
        artworkLocations,
        artworksByCategories: artworkCategoriesRestriction,
    });

    const {
      getSettingStatus
    } = useProductConfigState({product})

    const {
      showAllVariation,
    } = useThemeSettings({ settingData })


  const hideDecorationName = getSettingStatus('hideDecorationName');
    const hideDecorationType = getSettingStatus('hideDecorationType');
    const preventAutoAttach = getSettingStatus('preventAutoAttach');
    const artworkDuplicatesAllowed = getSettingStatus('artworkDuplicatesAllowed');


  useEffect(() => {
        if(tempArtworkList === null) {
        setTempArtworkList(artworkListById)
        }
    }, [artworkListById])

  useEffect(() => {
    if( ( artworkSetting && artworkGlobalIndicator ) && artworkListById.length === 0 ) {
      setArtworksFromStore(true)
      artworkListById = artworkListById?.concat(artworkList?.filter(al => artworkListById?.every(ali => ali?.id != al?.id)));
    } else {
      if( !artworkGlobalIndicator && artworkListById.length === 0 ) {
        setArtworksFromStore(true)
        artworkListById = artworkListById?.concat(artworkList?.filter(al => artworkListById?.every(ali => ali?.id != al?.id)));
      }
    }
  }, [ artworkList, artworkListById, searchTextArtwork, artworkGlobalIndicator ])

  useEffect(()=>{
		let temp =artworkListById.filter((item) => {
			if (item.artworkSource == 'aether' && (!(!!user?.userId) || artworkSetting)) {
				if (allowCustomDecoration || artworkSetting) {
					return item
				}
			} else {
				return item
			}
		})
        temp = temp.filter((item =>item.singleUseOnly == '0'))
		let tempartworkListById=temp.filter((item => item.identity&&item.identity.toLowerCase().indexOf(searchTextArtwork.length>0?searchTextArtwork.toLowerCase():searchTextArtwork) !== -1 || item.designNo&&item.designNo.toLowerCase().indexOf(searchTextArtwork.length>0?searchTextArtwork.toLowerCase():searchTextArtwork) !== -1 || item.designTypeName&&item.designTypeName.toLowerCase().indexOf(searchTextArtwork.length>0?searchTextArtwork.toLowerCase():searchTextArtwork) !== -1));
        if(!!decorationListOrder && decorationListOrder.length > 0 && artworkListById.length > 0) {
            tempartworkListById = tempartworkListById.sort(function(a,b) {
              return decorationListOrder.indexOf(a.id) - decorationListOrder.indexOf(b.id);
            })
        }
	    setArtworkListById(tempartworkListById)
	},[searchTextArtwork,artworkListById])
    useEffect(() => {
        if (selectedProductArtwork) {
            let artwork = artworkListById.filter(val => val.id == selectedProductArtwork)
            if (artwork.length > 0) {
                setArtwork_selected(artwork[0])
            }

        } else if (artworkListById && artworkListById.length) {
            let locationCount = 0
            artworkListById.map((val, key) => {
                if (val?.productToDecorationMapping?.[0]?.location === selectedProductLocation && val?.productToDecorationMapping?.[0]?.isRequiredForStore == '1') {
                    val.productToDecorationMapping[0].mapping.map((mapVal, mapkey) => {
                        if (mapVal.color === selectedProductColor) {
                            locationCount = locationCount + 1
                            setFindProductArtvariationId(mapVal.variationId)
                            setProductDesignId(val?.id)
                        }
                    })

                    if (locationCount > 1) {
                        // setShowData('yes')
                        setFindProductArtvariationId('')
                        setProductDesignId('')
                    }
                } else {
                    setFindProductArtvariationId('')
                    setProductDesignId('')
                    //setShowData('yes')
                }
            })
        }
    }, [artworkListById, selectedProductArtwork])

    useEffect(() => {
        if (artworkListById && artworkListById.length) {
          let locationCount = 0
          let tempartWorkCount = 0
          artworkListById.map((val, key) => {
            if (val?.productToDecorationMapping?.[0]?.location === selectedProductLocation && val?.productToDecorationMapping?.[0]?.isRequiredForStore == '1') {
              tempartWorkCount = tempartWorkCount + 1
              val.productToDecorationMapping[0].mapping.map((mapVal, mapkey) => {
                if (mapVal.color === selectedProductColor) {
                  locationCount = locationCount + 1
                  setFindProductArtvariationId(mapVal.variationId)
                  setProductDesignId(val?.id)
                }
              })
    
              if (locationCount > 1) {
                setFindProductArtvariationId('')
                setProductDesignId('')
              }
            } else {
              setFindProductArtvariationId('')
              setProductDesignId('')
              val?.productToDecorationMapping?.length > 0 && val.productToDecorationMapping.map((val) => {
              if (val.location == selectedProductLocation && val.isRequiredForStore == '0') {
                  tempartWorkCount = tempartWorkCount + 1
                  setTimeout(() => {
                    if (tempartWorkCount == 1 && !!document.querySelectorAll(".artMain")?.length && document.querySelectorAll(".artMain")?.length === 1 && !isAdded && !preventAutoAttach) {
                      setIsAdded(true)
                      let id = 'artew_' + '1'
                      var link = document.getElementById(id);
                      link && link.click()
                    }
                  }, 2000)
                } else if(val.location == selectedProductLocation && val.isRequiredForStore == '1') {
                    setTimeout(() => {
                        const requiredDecoElements = document.querySelectorAll(".requiredDeco");
                        if (requiredDecoElements.length === 1 && !isAdded && !preventAutoAttach) {
                          setIsAdded(true);
                          const id = `artew_1`;
                          const link = document.getElementById(id);
                          link && link.click();
                        }
                    }, 2000)
                }
              })
            }
          })
        }
    }, [artworkListById, selectedProductLocation, selectedProductColor])

  const handleArtworkselection = ( artwork, variation ) => {

    setSelectedProductArtwork(artwork.id)

    setArtwork_selected(artwork)
    if( artwork.design.variation.length === 1 ) {
      addDecoration(artwork.design.variation[0], artwork.id, selectedProductLocation, selectedProductColor, artworkListById)
    } else {
      let checkLocation = artwork.productToDecorationMapping.filter(val => val.location === selectedProductLocation)
      if( checkLocation.length >= 1 ) {
        let selectedVariation = artwork.design.variation.find(val => val.design_variation_unique_id === variation.variationId)
        addDecoration(selectedVariation, artwork.id, selectedProductLocation, selectedProductColor, artworkListById)
      } else {
        const selectedVariation = (artwork.design?.variation || []).find(res => res.design_variation_unique_id === variation.variationId)
        if( selectedVariation ) {
          addDecoration(selectedVariation, artwork.id, selectedProductLocation, selectedProductColor, artworkListById)
        } else if( !!artwork?.design?.variation?.[0] ) {
          addDecoration(artwork?.design?.variation?.[0], artwork.id, selectedProductLocation, selectedProductColor, artworkListById)
        }
      }
    }
  }

    useEffect(() => {
        let newLocation = []
        tempDecorationLocations &&
          tempDecorationLocations.map((val, index) => {
            let artworkSelected = null
            let checkLocation =
            selectedDecorationsList && selectedDecorationsList.find((d) => d.locationId === val.id && d.variationUniqID && d.variationUniqID !== "" && d.variationUniqID !== undefined && allowOneDec !== "2")
            if (checkLocation === undefined || checkLocation === '') {
              newLocation.push(val)
            }
          })
        console.log('tempDecorationLocations', tempDecorationLocations)
        setRemainDecoLocation(newLocation)
      }, [selectedDecorationsList, tempDecorationLocations])

    useLayoutEffect(() => {
        let timeoutId = null;
        if (artworkListRef.current) {
            timeoutId = setTimeout(() => {
                const requiredDecoElement = artworkListRef.current.querySelector('.requiredDeco');
                if (requiredDecoElement) {
                    artworkListRef.current.classList.add('requiredDiv');
                } else {
                    artworkListRef.current.classList.remove('requiredDiv');
                }
            }, 2000);
        }
        return () => {
            if (timeoutId) {
                clearTimeout(timeoutId);
            }
        };
    }, [artworkListRef.current, locationID]);

    const getArtworkClass = (artwork, isExistLocations) => {
        const designId = artwork.designId || artwork.id;
        if ((artwork_selected?.id === designId) || (isExistLocations?.[0]?.artworkID === (designId) )){
            return  "col-md-2 artworkbox decorows_selartwork artworkbox-selectednot"
        }
        return "col-md-2 artworkbox artworkbox-selected decorows_selartwork";
    }

    if( remainDecoLocation.length === 0 ) {
      return null;
    }
  return (
    <div className="col-md-12 mt-4">
      <div className="row pl-2 pr-4">
        <div className="col-md">
          <b>Select Artwork:</b>
          { productDesignId ? (
            <div>
              <div className="artworkList" ref={ artworkListRef } style={ { display: "flex", overflowX: "auto" } }>
                { tempArtworkListById.length > 0 && showArtwork && tempArtworkListById.map(( artwork, index ) => {
                  let imgUrls = [];
                  let showAllArtworkVariations = artworksFromStore ? showAllVariation : false;
                  let getCustomArtworkByUser = true;
                  let displayArtwork = true;
                  if( artworkDuplicatesAllowed === false ) {
                    let isExist = selectedDecorationsList.filter(decoration => decoration.artworkID == artwork.id);
                    if( selectedDecorationsList.length > 0 && isExist.length > 0 ) {
                      displayArtwork = false;
                    }
                  }
                  let isExistLocations = null;
                  if( selectedDecorationsList?.length > 0 ) {
                    isExistLocations = selectedDecorationsList.filter(decoration => (
                      ( decoration?.location === artwork?.artworkLocation ||
                        artwork.productToDecorationMapping.some(res => res.locationName === decoration.location) ) &&
                      decoration?.isRequiredForStore === '1'
                    ));
                  }
                  if( displayArtwork === true && getStatusLocation(artwork.id) ) {
                    let storeItem = storeArtwork.filter(item => item.designId == artwork?.designId);
                    getCustomArtworkByUser = ( storeItem?.[0]?.userId === user.userId );
                    let checkLocation = artwork.productToDecorationMapping.filter(val => val.location == selectedProductLocation);

                    if( checkLocation.length >= 1 && checkLocation?.[checkLocation.length - 1]?.showAllVariations === '0' ) {
                      let getVariationId = checkLocation[checkLocation.length - 1]
                        .mapping.filter(val => val.color === selectedProductColor);
                      if( getVariationId.length > 0 ) {
                        getVariationId.forEach(variation => {
                          let imageUrl = artwork.design.variation.find(val =>
                            val.design_variation_unique_id === variation.variationId);
                          if( imageUrl ) {
                            imgUrls.push({
                              itemImageThumbnail: imageUrl.itemImageThumbnail?.[0],
                              variationId: variation.variationId,
                            });
                          }
                        });
                      }
                    } else {
                      if( checkLocation?.[checkLocation.length - 1] ) {
                        showAllArtworkVariations = checkLocation[checkLocation.length - 1].showAllVariations === '1';
                      }
                      const variationsToShow =  artwork.design.variation.filter(res => {
                        const variationColor = typeof res.design_variation_color === 'string' ? res.design_variation_color : '';
                        if((variationColor === '' || variationColor.split(',')
                            .map(word => word.toLowerCase().trim())
                            .includes(selectedProductColor.toLowerCase()) || showAllArtworkVariations )
                          && !!res?.itemImageThumbnail?.[0] ) {
                          return res;
                        }
                      });
                      if( ( !( !!artwork?.artworkLocation ) || artwork?.artworkLocation === locationName ) &&
                        artwork?.design?.variation != "" && (artwork.productToDecorationMapping == "" || showAllArtworkVariations ) ) {
                        if( variationsToShow && variationsToShow.length > 0 ) {
                          variationsToShow.forEach(variation => {
                            if( storeItem && storeItem.length > 0 && artworkGlobalIndicator ) {
                              if( storeItem[0].isEnable
                                && ( getCustomArtworkByUser
                                  || artworkSetting
                                  || allowCustomDecoration
                                  || artwork.artworkSource !== 'aether'
                                  && !storeItem[0].userId ) ) {
                                imgUrls.push({
                                  itemImageThumbnail: variation.itemImageThumbnail?.[0],
                                  variationId: variation.design_variation_unique_id,
                                });
                              }
                            } else if( artwork?.statusId === "5" ) {
                              imgUrls.push({
                                itemImageThumbnail: variation.itemImageThumbnail?.[0],
                                variationId: variation.design_variation_unique_id,
                              });
                            }
                          });
                        } else if( storeItem && storeItem.length > 0 && artworkGlobalIndicator && artwork?.design?.variation?.length > 0 ) {
                          if( storeItem[0].isEnable
                            && ( getCustomArtworkByUser
                              || artworkSetting
                              || allowCustomDecoration
                              || ( artwork.artworkSource !== 'aether' && !storeItem[0].userId ) ) ) {
                            variationsToShow.forEach(variation => {
                              imgUrls.push({
                                itemImageThumbnail: variation.itemImageThumbnail?.[0],
                                variationId: variation.design_variation_unique_id
                              });
                            });
                          }
                        } else if( artwork?.statusId === "5" ) {
                          variationsToShow.forEach(variation => {
                            imgUrls.push({
                              itemImageThumbnail: variation.itemImageThumbnail?.[0],
                              variationId: variation.design_variation_unique_id
                            });
                          });
                        }
                      }
                    }
                    let isrequired = false;
                    if( isExistLocations?.[0]?.artworkID == ( artwork?.designId || artwork.id ) && isExistLocations?.[0]?.isRequiredForStore == "1" ) {
                      isrequired = true;
                    }
                    if( imgUrls.length > 0 ) {
                      return (
                        <div className={ `${ isrequired ? "requiredDeco" : "" }` } key={ 'art2' + index }>
                          { ( productDesignId === artwork?.designId || productDesignId === artwork?.id ) &&
                            imgUrls.map(( variation, imgIndex ) => (
                              <div
                                className={ 'col-md artworkbox artworkbox-selected decorows_selartwork' }
                                onClick={ () => {
                                  handleArtworkselection(artwork, variation);
                                } }
                                key={ index + variation.variationId }
                              >
                                <img
                                  key={ imgIndex + variation.variationId}
                                  src={ replaceOrigin(variation.itemImageThumbnail) }
                                  className="img-fluid mt-2"
                                  alt="No Image"
                                  style={ { maxHeight: '30px' } }
                                />
                                <br/>
                                { !!!hideDecorationType && (
                                  <label
                                    style={ { display: 'inline-block', fontWeight: 'bold', wordBreak: 'break-word' } }>
                                    { artwork.designTypeName }
                                  </label>
                                ) }
                              </div>
                            ))
                          }
                        </div>
                      );
                    }
                  }
                }) }
              </div>
            </div>
          ) : (
            <div>
              <div className="artworkList" ref={ artworkListRef } style={ { display: "flex", overflowX: "auto" } }>
                { tempArtworkListById.length > 0 && showArtwork && tempArtworkListById.map(( artwork, index ) => {
                  let imgUrls = [];
                  let showAllArtworkVariations = artworksFromStore ? showAllVariation : false;
                  let displayArtwork = true;
                  let getCustomArtwotkByUser = true;
                  let storeItem = storeArtwork.filter(item => item.designId == artwork?.designId);
                  getCustomArtwotkByUser = ( user.userId && storeItem?.[0]?.userId === user.userId );
                  let checkLocation = artwork.productToDecorationMapping.filter(val => val.location == selectedProductLocation);
                  if( artworkDuplicatesAllowed === false ) {
                    let isExist = selectedDecorationsList.filter(decoration => decoration.artworkID == artwork.id);
                    if( selectedDecorationsList.length > 0 && isExist.length > 0 ) {
                      displayArtwork = false;
                    }
                  }
                  let isExistLocations = null;
                  if( selectedDecorationsList?.length > 0 ) {
                    isExistLocations = selectedDecorationsList.filter(decoration => (
                      ( decoration?.location === artwork?.artworkLocation ||
                        artwork.productToDecorationMapping.some(res => res.locationName === decoration.location) ) &&
                      decoration?.isRequiredForStore === '1'
                    ));
                  }
                  if( displayArtwork === true && getStatusLocation(artwork.id) ) {
                    if( checkLocation.length >= 1 && checkLocation?.[checkLocation.length - 1]?.showAllVariations === '0' ) {
                      let getVariationId = checkLocation[checkLocation.length - 1]
                        .mapping.filter(val => val.color === selectedProductColor );
                      if( getVariationId.length > 0 ) {
                        getVariationId.forEach(variation => {
                          let imageUrl = artwork.design.variation.find(val =>
                            val.design_variation_unique_id === variation.variationId);
                          if( imageUrl ) {
                            imgUrls.push({
                              itemImageThumbnail: imageUrl.itemImageThumbnail?.[0],
                              variationId: variation.variationId,
                            });
                          }
                        });
                      }
                    } else {
                      if( checkLocation?.[checkLocation.length - 1] ) {
                        showAllArtworkVariations = checkLocation[checkLocation.length - 1].showAllVariations === '1';
                      }
                      const variationsToShow =  artwork.design.variation.filter(res => {
                        const variationColor = typeof res.design_variation_color === 'string' ? res.design_variation_color : '';
                        if((variationColor === '' || variationColor.split(',')
                            .map(word => word.toLowerCase().trim())
                            .includes(selectedProductColor.toLowerCase()) || showAllArtworkVariations )
                          && !!res?.itemImageThumbnail?.[0] ) {
                          return res;
                        }
                      });
                      if( ( !( !!artwork?.artworkLocation ) || artwork?.artworkLocation === locationName ) &&
                        artwork?.design?.variation != "" && (artwork.productToDecorationMapping == "" || showAllArtworkVariations) ) {
                        if( variationsToShow && variationsToShow.length > 0 ) {
                          variationsToShow.forEach(variation => {
                            if( storeItem && storeItem.length > 0 && artworkGlobalIndicator ) {
                              if( storeItem[0].isEnable
                                && artworkSetting && ( getCustomArtwotkByUser
                                  || allowCustomDecoration
                                  || artwork.artworkSource !== 'aether'
                                  && !storeItem[0].userId ) ) {
                                imgUrls.push({
                                  itemImageThumbnail: variation.itemImageThumbnail?.[0],
                                  variationId: variation.design_variation_unique_id,
                                });
                              }
                            } else if( artwork?.statusId === "5" ) {
                              imgUrls.push({
                                itemImageThumbnail: variation.itemImageThumbnail?.[0],
                                variationId: variation.design_variation_unique_id,
                              });
                            }
                          });
                        } else {
                          if( storeItem && storeItem.length > 0 && artworkGlobalIndicator && artwork?.design?.variation?.length > 0 ) {
                            if( (storeItem[0].isEnable
                              && ( getCustomArtwotkByUser
                                || artworkSetting
                                || allowCustomDecoration
                                || ( artwork.artworkSource !== 'aether' && !storeItem[0].userId ) )
                              || artwork?.statusId === "5") ) {
                              variationsToShow.forEach(variation => {
                                imgUrls.push({
                                  itemImageThumbnail: variation.itemImageThumbnail?.[0],
                                  variationId: variation.design_variation_unique_id
                                });
                              });
                            }
                          }
                        }
                      }
                    }
                    let isrequired = false;
                    if( isExistLocations?.[0]?.artworkID == ( artwork.id || artwork.designId ) && isExistLocations?.[0]?.isRequiredForStore == "1" ) {
                      isrequired = true;
                    }
                    let customeIndex = 0;
                    if( imgUrls.length > 0 ) {
                      return imgUrls.map(( variation, imgIndex ) => (
                        <div className={ `${ isrequired ? "artMain requiredDeco" : "artMain" }` }
                             key={ 'artew_' + index + '_' + imgIndex }>
                          <div
                            className={ 'col-md artworkbox artworkbox-selected decorows_selartwork' }
                            onClick={ () => {
                              handleArtworkselection(artwork, variation);
                            } }
                            key={ index + '_' + imgIndex + variation.variationId }
                          >
                            <img
                              key={ 'artew_' + customeIndex + '_' + imgIndex + variation.variationId }
                              id={ 'artew_' + customeIndex }
                              src={ replaceOrigin(variation.itemImageThumbnail) }
                              className="img-fluid mt-3"
                              alt="No Image"
                              style={ { maxHeight: '30px' } }
                            />
                            {
                              !!!hideDecorationType && <label style={ { display: 'Block' } }>
                                { artwork.designTypeName }
                              </label>
                            }
                            { !!!hideDecorationName && <label style={ { display: 'Block' } }>
                              { artwork.identity }
                            </label> }
                          </div>
                        </div>
                      ));
                    }
                  }
                }) }
              </div>
            </div>
          ) }
        </div>
      </div>
    </div>
  );
}
const mapDispatchToProps = dispatch => {
  return {};
};
const mapStateToProps = state => {
  return {
    artworkList: state.productData.artworkList,
    artworkListById: state.productData.artworkListById,
    artworkCategoriesRestriction: state.productData.artworkCategoriesRestriction,
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(AddDecoration);


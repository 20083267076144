import { useContext } from "react";
import { AuthContext } from "../../../common/Auth";
import { useThemeSettingsData } from "../../../layouts/state/useThemeSettingsData";
import { _useToasts } from "../../../common/_useToasts";
import { getStock } from "../../../helpers/product";
import { useProductDecoCharge } from "../../../components/product/state/useProductDecoCharge";
import { useThemeSettings } from "../../../layouts/state/useThemeSettings";

export const useProductConfigState = ( props ) => {
  const { themeSettingsData, user, settingData } = useContext(AuthContext);
  const {
    product,
    cartItems,
    catalogueCustomRows,
    artworksFromState,
    artworksProductState,
    allowCustomDecoration,
    selectedProductColor,
    selectedProductDecorations,
    selectedProductLocation,
    artworkSetting,
    selectedLocation,
    selectedLocationIndex,
  } = props;
  const inventoryKey = "showInventory"
  const { _addToast } = _useToasts();


  const {
    getDecoChargesByQuantity
  } = useProductDecoCharge({ artworksFromState })

  const {
    showAllVariation,
  } = useThemeSettings({ settingData })

  const {
    inventorySettings,
    productSettings,
  } = useThemeSettingsData({ themeSettingsData })


  const getInventorySettingStatus = ( product, key = inventoryKey ) => {
    if( product[key] === false || product[key] === true ) {
      return product[key]
    }
    if( inventorySettings ) {
      if( inventorySettings[key] === false || inventorySettings[key] === true ) {
        return inventorySettings[key]
      }
    }
    return false
  }

  const orderAction = ( msg, type, loading, autoDismiss = true ) => {
    _addToast(msg, {
      appearance: type,
      autoDismiss: autoDismiss,
    })
  }

  const getCartItemTotal = ( productId, color, size ) => {
    let totalqty = 0
    !!cartItems && cartItems.length > 0 && cartItems.forEach(( caItems ) => {
      if( caItems?.product?.productId === productId ) {
        return caItems?.currentOrder.forEach(( sku ) => {
          return sku?.forEach(( skuItem ) => {
            if( color && size ) {
              if( color === skuItem.color && size === skuItem.size ) {
                totalqty += Number(skuItem?.quantity)
              }
            } else {
              totalqty += Number(skuItem?.quantity)
            }
          })
        })
      }
    })
    return totalqty
  }

  const checkMinQty = ( prodId, totalQty, isRestrictQty, minAllQty, isMoq, isSkuMoq, minQty, item = null, doNotCalculateAtOrder = 0, productLayout = '' ) => {
    let itemsFromCart = 0
    if( doNotCalculateAtOrder != "1" && isRestrictQty ) {
      itemsFromCart = getCartItemTotal(prodId);
    }
    let total = itemsFromCart + Number(totalQty);
    if( isRestrictQty ) {
      let minQTy = minAllQty
      if( minAllQty.length > 1 ) {
        minQTy = minAllQty.join(', ')
      }
      if( !minAllQty.includes(total) && isSkuMoq ) {
        console.log('minQTy: ', minQTy);
        orderAction(`Order quantity for this product must be ${ minQTy.toString() }. If you have already added this product to your cart that quantity will be included.`, 'error', false)
        return false;
      } else {
        if( !isSkuMoq ) {
          let restriction = false;
          if( catalogueCustomRows ) {
            catalogueCustomRows.forEach(catalogueCustomRow => {
              catalogueCustomRow.forEach(productRow => {
                if( !!productRow.color && !!productRow.size && !!productRow.quantity ) {
                  let itemsFromCart = 0
                  if( doNotCalculateAtOrder != "1" ) {
                    itemsFromCart = getCartItemTotal(prodId, productRow.color, productRow.size);
                  }
                  let total = itemsFromCart + Number(productRow.quantity);
                  if( !minAllQty.includes(total) ) {
                    restriction = true;
                  }
                }
              })
            })
          } else {
            if( ( ( !!item.color && !!item.size ) || productLayout === 'printableLayout' ) && !!totalQty ) {
              let itemsFromCart = 0
              if( doNotCalculateAtOrder != "1" ) {
                itemsFromCart = getCartItemTotal(prodId, item.color, item.size);
              }
              let total = itemsFromCart + Number(totalQty);
              if( !minAllQty.includes(total) ) {
                restriction = true;
              }
            }
          }
          if( restriction ) {
            orderAction(`Order quantity for this product must be ${ minQTy.toString() }. If you have already added this product to your cart that quantity will be included.`, 'error', false)
            return false;
          }
        }
      }
    }
    if( isSkuMoq ) {
      if( Number(total) < Number(minQty) ) {
        orderAction(`Minimum quantity should be ${ minQty }`, 'error', false)
        return false;
      }
    } else {
      if( isMoq ) {
        let restriction = false;
        if( catalogueCustomRows ) {
          catalogueCustomRows.forEach(catalogueCustomRow => {
            catalogueCustomRow.forEach(productRow => {
              if( !!productRow.color && !!productRow.size && !!productRow.quantity ) {
                let itemsFromCart = getCartItemTotal(prodId, productRow.color, productRow.size);
                let total = itemsFromCart + Number(productRow.quantity);
                if( Number(total) < Number(minQty) ) {
                  restriction = true;
                }
              }
            })
          })
        } else {
          if( ( ( !!item.color && !!item.size ) || productLayout === 'printableLayout' ) && !!totalQty ) {
            let itemsFromCart = getCartItemTotal(prodId, item.color, item.size);
            let total = itemsFromCart + Number(totalQty);
            if( Number(total) < Number(minQty) ) {
              restriction = true;
            }
          }
        }
        if( restriction ) {
          orderAction(`Minimum quantity should be ${ minQty }`, 'error', false)
          return false;
        }
      }
    }
    return true;
  }

  const getDecorationCost = ( selectedProductDecorations, quantityCount, customDecorationFee, showSetupChargeInCart, customDecorationPerUnitPrice, toatlPrice, customDecorationSetupFee, allowCustomDecoration, doNotCalculateAtOrder = "0", isRestrictQty = false ) => {
    let totalDecoCharges = 0;
    let totalSetupDecoCharges = 0
    if( getSettingStatus('showDecoration') ) {
      for( let decoData of selectedProductDecorations ) {
        let decoChargeArray = getDecoChargesByQuantity([ decoData ], quantityCount);
        let artwork = null;
        if( artworksFromState?.length ) {
          artwork = artworksFromState.find(
            ( artworkArray ) => ( artworkArray.id === decoData.artworkID && artworkArray.artworkLocation === decoData.location ),
          )
          if( !!!artwork && artworksProductState?.length ) {
            artwork = artworksProductState.find(( artworkArray ) =>
              artworkArray?.productToDecorationMapping?.some(
                ( res ) => res.locationName === decoData.location && artworkArray.id === decoData.artworkID
              )
            );
          }
        }
        if( artwork?.artworkSource == 'aether' && ( ( getSettingStatus('showDecoration') && customDecorationFee && allowCustomDecoration ) || ( showSetupChargeInCart && !getSettingStatus('showDecoration') ) ) ) {
          if( showSetupChargeInCart ) {
            toatlPrice = Number(toatlPrice) + Number(customDecorationSetupFee);
            totalSetupDecoCharges = Number(totalSetupDecoCharges) + Number(customDecorationSetupFee)
          }
          let totalDecoCost = ( Number(customDecorationPerUnitPrice ?? 0) * Number(quantityCount) )
          if( doNotCalculateAtOrder == "1" && isRestrictQty ) {
            totalDecoCost = Number(customDecorationPerUnitPrice ?? 0)
          }
          totalDecoCharges = Number(totalDecoCharges) + Number(totalDecoCost)
        } else {
          let setupChargeSalePrice = decoChargeArray.reduce(( acc, curr ) => acc + Number(curr.setupChargeSalePrice || 0), 0);
          let salePrice = decoChargeArray.reduce(( acc, curr ) => acc + Number(curr.salePrice || 0), 0)
          if( showSetupChargeInCart ) {
            toatlPrice = Number(toatlPrice) + Number(setupChargeSalePrice)
            totalSetupDecoCharges = Number(totalSetupDecoCharges) + Number(setupChargeSalePrice)
          }
          let totalDecoCost = ( Number(salePrice) * Number(quantityCount) )
          if( doNotCalculateAtOrder == "1" && isRestrictQty ) {
            totalDecoCost = salePrice
          }
          totalDecoCharges = Number(totalDecoCharges) + Number(totalDecoCost)

        }
      }
    }
    return { totalDecoCharges, totalSetupDecoCharges, toatlPrice };
  }


  const getSettingStatus = ( key, defaultValue = null ) => {
    if( product?.[key] === false || product?.[key] === true ) {
      return product[key]
    }
    return ( defaultValue !== null ? defaultValue : productSettings?.[key] )
  }


  const getLocations = () => {
    let decoExistLocations = [];
    let locationName = '';
    let locID = ''
    selectedProductDecorations?.length > 0 && selectedProductDecorations.map(decoFilter => {
      locID = selectedProductLocation !== undefined && selectedProductLocation !== "" ? selectedProductLocation : decoFilter.locationId
      if( decoFilter.locationId === locID )
        locationName = decoFilter.location
    })
    if( !( !!locationName ) && product?.LocationArray?.Location ) {
      locID = selectedProductLocation !== undefined && selectedProductLocation !== "" ? selectedProductLocation : ''
      let localName = product.LocationArray.Location.filter(location => ( location.locationId === locID && location?.locationName !== "" ) ? location?.locationName : '')
      locationName = !!localName?.[0]?.locationName ? localName?.[0]?.locationName : ''
    }
    if( product?.LocationArray?.Location ) {
      product.LocationArray.Location.map(location => {
        if( location?.locationName !== "" )
          decoExistLocations.push(location.locationName)
      })
    }
    let yFilter = []
    artworksFromState.map(itemY => {
      let variationId = [];
      itemY.design.variation.map(designVal => {
        if( designVal?.itemImageThumbnail?.length == 0 ) {
          variationId.push(designVal.design_variation_unique_id)
        }
      })
      let getUsrPrlocation = false
      if( ( itemY?.artworkSource === "aether" && user.userId ) || allowCustomDecoration || itemY?.artworkSource !== "aether" )
        getUsrPrlocation = true
      if( itemY.productToDecorationMapping.length > 0 ) {
        itemY.productToDecorationMapping.map(( parentVal ) => {
          parentVal.mapping.map(( val ) => {
            if( val.color == selectedProductColor && !variationId.includes(val.variationId) ) {
              if( !yFilter.includes(parentVal.locationName) && getUsrPrlocation && parentVal.locationName ) {
                yFilter.push(parentVal.locationName)
              }
            }
          })
        })
      }
      if( ( decoExistLocations.indexOf(itemY?.artworkLocation) !== -1 || !( !!itemY?.artworkLocation ) ) &&
        itemY.productToDecorationMapping.length === 0 && itemY?.design?.variation !== "") {
        if( !!itemY?.design?.variation && itemY?.design?.variation?.length === 1 ) {
          if( !yFilter.includes(itemY?.artworkLocation) && !!itemY?.artworkLocation ) {
            yFilter.push(itemY.artworkLocation)
          }
        } else {
          !!itemY?.design?.variation && itemY.design.variation.map(res => {
            if( !!res?.design_variation_color && res?.design_variation_color.split(',').includes(selectedProductColor) || showAllVariation ) {
              if( !yFilter.includes(itemY?.artworkLocation) && !!itemY?.artworkLocation ) {
                yFilter.push(itemY.artworkLocation)
              }
            }
          })
        }
        if( !( !!itemY?.artworkLocation ) ) {
          decoExistLocations.map(( resLoc ) => {
            if( !yFilter.includes(resLoc) && !!resLoc ) {
              yFilter.push(resLoc)
            }
          })
        }
      }
      if( ( decoExistLocations.indexOf(itemY?.artworkLocation) !== -1 || !( !!itemY?.artworkLocation ) ) &&
        itemY.productToDecorationMapping.length === 0 && itemY?.design?.variation !== "" && artworkSetting && artworksProductState.length === 0) {
        if( !!itemY?.design?.variation && itemY?.design?.variation?.length === 1 ) {
          if( !yFilter.includes(itemY?.artworkLocation) && !!itemY.artworkLocation ) {
            yFilter.push(itemY.artworkLocation)
          }
        } else {
          !!itemY?.design?.variation && itemY.design.variation.map(res => {
            if( !!res?.design_variation_color && res?.design_variation_color.split(',').includes(selectedProductColor) || showAllVariation ) {
              if( !yFilter.includes(itemY?.artworkLocation) && !!itemY.artworkLocation ) {
                yFilter.push(itemY.artworkLocation)
              }
            }
          })
        }
      }
    })
    if( yFilter.length === 0 && artworkSetting ) {
      decoExistLocations.map(( resLoc ) => {
        if( !yFilter.includes(resLoc) && !!resLoc ) {
          yFilter.push(resLoc)
        }
      })
    }
    let locationIds = []
    if( product?.LocationArray?.Location ) {
      locationIds = product.LocationArray.Location.map(( location ) => location.locationId)
    }
    return { yFilter, decoExistLocations, locationName, locationIds };
  }

  const getLocationsCondensed = () => {
    const itemsRows = catalogueCustomRows[selectedLocationIndex ?? 0];
    let locID = ''
    let locationName = ''
    let artWorkID = ''
    let productColor = ''
    let decoExistLocations = []
    let yFilter = []
    if( itemsRows?.[0] ) {
      productColor = itemsRows?.[0]?.color
      itemsRows[0].decorations && itemsRows[0].decorations.map(( decoFilter, key ) => {
        if(
          decoFilter.variationUniqID === null ||
          !decoFilter?.artworkID ||
          decoFilter?.artworkID === null
        ) {
          artWorkID = decoFilter.artworkID
        }
        locID = selectedLocation !== undefined && selectedLocation !== "" ? selectedLocation : decoFilter.locationId
        if( decoFilter.locationId === locID )
          locationName = decoFilter.location
      })
      // logic for location drop-down
      /*
       1. get all location name from product.LocationArray
       2. check design thumbnail from artwork list if thumbnail not availabe then don't use location name.
       3. if thumbnail availabe then push all location from productToDecorationMapping
       */
      if( product?.LocationArray?.Location ) {
        product.LocationArray.Location.map(( location ) => {
          if( location?.locationName !== '' )
            decoExistLocations.push(location.locationName)
        })
      }
      artworksFromState?.length > 0 && artworksFromState.map(itemY => {
        let variationId = [];
        itemY.design.variation.map(designVal => {
          if( designVal?.itemImageThumbnail?.length == 0 ) {
            variationId.push(designVal.design_variation_unique_id)
          }
        })
        let getUsrPrlocation = false
        if( ( itemY?.artworkSource === "aether" && user.userId ) || allowCustomDecoration || itemY?.artworkSource !== "aether" )
          getUsrPrlocation = true
        if( itemY.productToDecorationMapping.length > 0 ) {
          itemY.productToDecorationMapping.map(( parentVal ) => {
            parentVal.mapping.map(( val ) => {
              if( val.color == productColor && !variationId.includes(val.variationId) ) {
                if( !yFilter.includes(parentVal.locationName) && getUsrPrlocation && !!parentVal.locationName ) {
                  yFilter.push(parentVal.locationName)
                }
              }
            })
          })
        }
        if( ( decoExistLocations.indexOf(itemY?.artworkLocation) !== -1 || !( !!itemY?.artworkLocation ) ) &&
          itemY.productToDecorationMapping.length === 0 && itemY?.design?.variation !== "" && artworksProductState.length === 0 ) {
          if( !!itemY?.design?.variation && itemY?.design?.variation?.length === 1 ) {
            if( !yFilter.includes(itemY?.artworkLocation) && !!itemY?.artworkLocation ) {
              yFilter.push(itemY.artworkLocation)
            }
          } else {
            !!itemY?.design?.variation && itemY.design.variation.map(res => {
              if( ( res?.design_variation_color || '' ).split(',').includes(productColor) || showAllVariation ) {
                if( !yFilter.includes(itemY?.artworkLocation) && !!itemY?.artworkLocation ) {
                  yFilter.push(itemY.artworkLocation)
                }
              }
            })
          }
          if( !( !!itemY?.artworkLocation ) ) {
            decoExistLocations.map(( resLoc ) => {
              if( !yFilter.includes(resLoc) && !!resLoc ) {
                yFilter.push(resLoc)
              }
            })
          }
        }
        if( ( decoExistLocations.indexOf(itemY?.artworkLocation) !== -1 || !( !!itemY?.artworkLocation ) ) &&
          itemY.productToDecorationMapping.length === 0 && itemY?.design?.variation !== "" && artworkSetting && artworksProductState.length === 0 ) {
          if( !!itemY?.design?.variation && itemY?.design?.variation?.length === 1 ) {
            if( !yFilter.includes(itemY?.artworkLocation) && !!itemY?.artworkLocation ) {
              yFilter.push(itemY.artworkLocation)
            }
          } else {
            !!itemY?.design?.variation && itemY.design.variation.map(res => {
              if( !!res?.design_variation_color && res?.design_variation_color.split(',').includes(productColor) || showAllVariation ) {
                if( !yFilter.includes(itemY?.artworkLocation) && !!itemY?.artworkLocation ) {
                  yFilter.push(itemY.artworkLocation)
                }
              }
            })
          }
        }
      })
      if( yFilter.length === 0 && artworkSetting ) {
        decoExistLocations.map(( resLoc ) => {
          if( !yFilter.includes(resLoc) && !!resLoc ) {
            yFilter.push(resLoc)
          }
        })
      }
    }
    let locationIds = []
    if( product?.LocationArray?.Location ) {
      locationIds = product.LocationArray.Location.map(( location ) => location.locationId)
    }

    return { locID, locationName, artWorkID, productColor, decoExistLocations, yFilter, locationIds }
  }

  return {
    getInventorySettingStatus,
    orderAction,
    getCartItemTotal,
    checkMinQty,
    getDecorationCost,
    getSettingStatus,
    getLocations,
    getLocationsCondensed,
  }
}

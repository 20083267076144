export const USERTYPES = {
    USER: 'user',
    ADMIN: 'admin',
    ANTERA_ADMIN: 'superadmin'
}

export enum PermissionList {
    DelegateApprovals = '5',
    ManageApprovals = '1',
    ManageYourUserGroup = '2',
    ViewGroupOrders = '3',
    ViewAllOrders = '4',
}
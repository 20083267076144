import React, { Fragment, useState, useEffect, useContext } from "react";
import { connect, useSelector } from "react-redux";
import { AuthContext } from "../../../common/Auth"
import { replaceOrigin } from "../../../util/helper";
import DeleteIcon from '@material-ui/icons/Delete'
import { useProductArtworkRestrictionState } from "../state/useProductArtworkRestrictionState";
import { useProductConfigState } from "../../../pages/shop-product/state/useProductConfigState";
import { useThemeSettings } from "../../../layouts/state/useThemeSettings";
const AddDecorationHats = ({ artworkList,
                               selectedProductArtwork,
                               setSelectedProductArtwork,
                               addDecoration,
                               selectedProductLocation,
                               artworkListById,
                               selectedProductColor,
                               locationName,
                               searchTextArtwork,
                               artworkSetting,
                               storeArtwork,
                               decorationLocations,
                               allowCustomDecoration,
                               selectedDecorationsList,
                               tempDecorationLocations,
                               product,
                               artworkLocations,
                               allowOneDec,
                               isAdded,
                               setIsAdded,
                               decorationListOrder,
                               showPopupText,
                               artworkCategoriesRestriction,
                           }) => {
    let getLocationName = decorationLocations && decorationLocations.filter(item => item.id === selectedProductLocation)
    const { storeDetails, userType, user, settingData } = useContext(AuthContext)
    const [productDesignId, setProductDesignId] = useState('')
    const [ArtworkDuplicatesAllowed, setArtworkDuplicatesAllowed] = useState(true)
    const [artwork_selected, setArtwork_selected] = useState(null)
    const [findProductArtvariationId, setFindProductArtvariationId] = useState('')
    const [tempArtworkListById, setArtworkListById] = useState(artworkListById)
    const [artworksFromStore, setArtworksFromStore] = useState(false)

    const [showArtwork, setShowArtwork] = useState(true)
    const [remainDecoLocation, setRemainDecoLocation] = useState(
      tempDecorationLocations,
    )

    const artworkGlobalIndicator = useSelector((state) => state.productData.artworkGlobalIndicator);

    const {
        getStatusLocation,
    } = useProductArtworkRestrictionState({
        tempArtworkList: tempArtworkListById,
        product,
        selectedDecorationsList,
        locationName,
        artworkLocations,
        artworksByCategories: artworkCategoriesRestriction
    });
    const {
        getSettingStatus
    } = useProductConfigState({product})


    const {
      showAllVariation,
    } = useThemeSettings({ settingData });
    const preventAutoAttach = getSettingStatus('preventAutoAttach');

  useEffect(() => {
    if( ( artworkSetting && artworkGlobalIndicator ) && artworkListById.length === 0 ) {
      artworkListById = artworkListById?.concat(artworkList?.filter(al => artworkListById?.every(ali => ali?.id != al?.id)));
    } else {
      if( !artworkGlobalIndicator && artworkListById.length === 0 ) {
        artworkListById = artworkListById?.concat(artworkList?.filter(al => artworkListById?.every(ali => ali?.id != al?.id)));
      }
    }
  }, [ artworkList, artworkListById, searchTextArtwork, artworkGlobalIndicator ])


  useEffect(() => {
        let temp = artworkListById.filter((item) => {
            if (item.artworkSource == 'aether' && (!(!!user?.userId) || artworkSetting)) {
                if (allowCustomDecoration || artworkSetting) {
                    return item
                }
            } else {
                return item
            }
        })
        temp = temp.filter((item => item.singleUseOnly == '0'))

        const artworkIDs = selectedDecorationsList.map(item => item.artworkID) || [];
        let tempartworkListById = temp.filter((item => item.identity && item.identity.toLowerCase().indexOf(searchTextArtwork.length > 0 ? searchTextArtwork.toLowerCase() : searchTextArtwork) !== -1 || item.designNo && item.designNo.toLowerCase().indexOf(searchTextArtwork.length > 0 ? searchTextArtwork.toLowerCase() : searchTextArtwork) !== -1 || item.designTypeName && item.designTypeName.toLowerCase().indexOf(searchTextArtwork.length > 0 ? searchTextArtwork.toLowerCase() : searchTextArtwork) !== -1 || artworkIDs.includes(item.id)));
        if (!!decorationListOrder && decorationListOrder.length > 0 && artworkListById.length > 0) {
            tempartworkListById = tempartworkListById.sort(function (a, b) {
                return decorationListOrder.indexOf(a.id) - decorationListOrder.indexOf(b.id);
            })
        }
        setArtworkListById(tempartworkListById)
    }, [searchTextArtwork, artworkListById])
    useEffect(() => {
        if (selectedProductArtwork) {
            let artwork = artworkListById.filter(val => val.id == selectedProductArtwork)
            if (artwork.length > 0) {
                setArtwork_selected(artwork[0])
            }

        } else if (artworkListById && artworkListById.length) {
            let locationCount = 0
            artworkListById.map((val, key) => {
                if (val?.productToDecorationMapping[0]?.location === selectedProductLocation && val?.productToDecorationMapping[0]?.isRequiredForStore == '1') {
                    val.productToDecorationMapping[0].mapping.map((mapVal, mapkey) => {
                        if (mapVal.color === selectedProductColor) {
                            locationCount = locationCount + 1
                            setFindProductArtvariationId(mapVal.variationId)
                            setProductDesignId(val?.id)
                        }
                    })

                    if (locationCount > 1) {
                        // setShowData('yes')
                        setFindProductArtvariationId('')
                        setProductDesignId('')
                    }
                } else {
                    setFindProductArtvariationId('')
                    setProductDesignId('')
                    //setShowData('yes')
                }
            })
        }
    }, [artworkListById, selectedProductArtwork])

    useEffect(() => {
        if (artworkListById && artworkListById.length) {
            let locationCount = 0
            let tempartWorkCount = 0
            artworkListById.map((val, key) => {

                if (val?.productToDecorationMapping[0]?.location === selectedProductLocation && val?.productToDecorationMapping[0]?.isRequiredForStore == '1') {
                    tempartWorkCount = tempartWorkCount + 1
                    val.productToDecorationMapping[0].mapping.map((mapVal, mapkey) => {
                        if (mapVal.color === selectedProductColor) {
                            locationCount = locationCount + 1
                            setFindProductArtvariationId(mapVal.variationId)
                            setProductDesignId(val?.id)
                        }
                    })

                    if (locationCount > 1) {
                        setFindProductArtvariationId('')
                        setProductDesignId('')
                    }
                } else {
                    setFindProductArtvariationId('')
                    setProductDesignId('')
                    val.productToDecorationMapping.map((val) => {
                        if (val.location == selectedProductLocation && val.isRequiredForStore == '0') {
                            tempartWorkCount = tempartWorkCount + 1
                            setTimeout(() => {
                                if (tempartWorkCount == 1 && !!document.querySelectorAll(".artMain")?.length && document.querySelectorAll(".artMain")?.length === 1 && !isAdded && !preventAutoAttach) {
                                    setIsAdded(true)
                                    let id = 'artew_' + '1'
                                    var link = document.getElementById(id);
                                    link && link.click()
                                }
                            }, 2000)
                        }
                    })
                }
            })
        }
    }, [artworkListById, selectedProductLocation, selectedProductColor])

    const handleArtworkselection = (artwork, variation) => {
        setSelectedProductArtwork(artwork.id)
        setArtwork_selected(artwork)
        if (artwork.design.variation.length === 1) {
            addDecoration(artwork.design.variation[0], artwork.id, selectedProductLocation, selectedProductColor, artworkListById)
        } else {
            let checkLocation = artwork.productToDecorationMapping.filter(val => val.location === selectedProductLocation)
            if (checkLocation.length >= 1) {
              let selectedVariation = artwork.design.variation.find(val => val.design_variation_unique_id === variation.variationId)
              addDecoration(selectedVariation, artwork.id, selectedProductLocation, selectedProductColor, artworkListById)
            } else {
              const selectedVariation = !!artwork?.design?.variation && artwork.design.variation.find(res => res.design_variation_unique_id === variation.variationId)
              if( selectedVariation ) {
                addDecoration(selectedVariation, artwork.id, selectedProductLocation, selectedProductColor, artworkListById)
              } else if( !!artwork?.design?.variation?.[0] ) {
                addDecoration(artwork?.design?.variation?.[0], artwork.id, selectedProductLocation, selectedProductColor, artworkListById)
              }
            }
        }
    }

    useEffect(() => {
        let newLocation = []
        tempDecorationLocations &&
        tempDecorationLocations.map((val, index) => {
            let artworkSelected = null
            let checkLocation =
              selectedDecorationsList && selectedDecorationsList.find((d) => d.locationId === val.id && d.variationUniqID && d.variationUniqID !== "" && d.variationUniqID !== undefined && allowOneDec !== "2")
            if (checkLocation === undefined || checkLocation === '') {
                newLocation.push(val)
            }
        })
        setRemainDecoLocation(newLocation)
    }, [selectedDecorationsList, tempDecorationLocations])

    const getArworkClass = (artwork) => {
        return !!artwork ? "artworkbox artworkbox-selected decorows_selartwork" : "artworkbox artworkbox-selectednot decorows_selartwork"
    }

  return (
    <div className="location-hats-list">
      { productDesignId ? (
        <div className="location-hats-data mb-10">
          { tempArtworkListById.length > 0 && showArtwork && tempArtworkListById.map(( artwork, index ) => {
            let showAllArtworkVariations = artworksFromStore ? showAllVariation : false;
            let imgUrls = [];
            let getCustomArtworkByUser = true;
            let displayArtwork = true;
            if( ArtworkDuplicatesAllowed === false ) {
              let isExist = selectedDecorationsList.filter(decoration => decoration.artworkID == artwork.id);
              if( selectedDecorationsList.length > 0 && isExist.length > 0 ) {
                displayArtwork = false;
              }
            }
            let isExistLocations = null;
            if( selectedDecorationsList?.length > 0 ) {
              isExistLocations = selectedDecorationsList.filter(decoration => (
                ( decoration?.location === artwork?.artworkLocation ||
                  artwork.productToDecorationMapping.some(res => res.locationName === decoration.location) ) &&
                decoration?.isRequiredForStore === '1'
              ));
            }
            if( displayArtwork === true && getStatusLocation(artwork.id) ) {
              let storeItem = storeArtwork.filter(item => item.designId == artwork?.designId);
              getCustomArtworkByUser = ( storeItem?.[0]?.userId === user.userId );
              let checkLocation = artwork.productToDecorationMapping.filter(val => val.location == selectedProductLocation);

              if( checkLocation.length >= 1 && checkLocation?.[checkLocation.length - 1]?.showAllVariations === '0') {
                let getVariationId = checkLocation[checkLocation.length - 1].mapping.filter(val => val.color === selectedProductColor);
                if( getVariationId.length > 0 ) {
                  getVariationId.forEach(variation => {
                    let imageUrl = artwork.design.variation.find(val => val.design_variation_unique_id == variation.variationId);
                    if( imageUrl ) {
                      imgUrls.push({
                        itemImageThumbnail: imageUrl.itemImageThumbnail?.[0],
                        variationId: variation.variationId,
                      })
                    }
                  });
                }
              } else {
                if( checkLocation?.[checkLocation.length - 1] ) {
                  showAllArtworkVariations = checkLocation[checkLocation.length - 1].showAllVariations === '1';
                } {
                  showAllArtworkVariations = showAllVariation; //drop here for show all variations from local setting
                }
                const variationsToShow =  artwork.design.variation.filter(res => {
                  const variationColor = typeof res.design_variation_color === 'string' ? res.design_variation_color : '';
                  if((variationColor === '' || variationColor.split(',')
                      .map(word => word.toLowerCase().trim())
                      .includes(selectedProductColor.toLowerCase()) || showAllArtworkVariations )
                    && !!res?.itemImageThumbnail?.[0] ) {
                    return res;
                  }
                });
                if( ( !( !!artwork?.artworkLocation ) || artwork?.artworkLocation === locationName ) &&
                  artwork?.design?.variation != "" && (artwork.productToDecorationMapping == "" || showAllArtworkVariations) ) {
                  if (variationsToShow && variationsToShow.length > 0) {
                    variationsToShow.forEach(variation => {
                      if (storeItem && storeItem.length > 0 && artworkGlobalIndicator) {
                        if (storeItem[0].isEnable
                          && (getCustomArtworkByUser
                            || artworkSetting
                            || allowCustomDecoration
                            || artwork.artworkSource !== 'aether'
                            && !storeItem[0].userId)) {
                          imgUrls.push({
                            itemImageThumbnail: variation.itemImageThumbnail?.[0],
                            variationId: variation.design_variation_unique_id,
                          });
                        }
                      } else if (artwork?.statusId === "5") {
                        imgUrls.push({
                          itemImageThumbnail: variation.itemImageThumbnail?.[0],
                          variationId: variation.design_variation_unique_id,
                        });
                      }
                    });
                  } else if( storeItem && storeItem.length > 0 && artworkGlobalIndicator && artwork?.design?.variation?.length > 0 ) {
                    if( storeItem[0].isEnable
                      && ( getCustomArtworkByUser
                        || artworkSetting
                        || allowCustomDecoration
                        || ( artwork.artworkSource !== 'aether' && !storeItem[0].userId ) ) ) {
                      variationsToShow.forEach(variation => {
                        imgUrls.push({
                          itemImageThumbnail: variation.itemImageThumbnail?.[0],
                          variationId: variation.design_variation_unique_id
                        });
                      });
                    }
                  } else if( artwork?.statusId === "5" ) {
                    variationsToShow.forEach(variation => {
                      imgUrls.push({
                        itemImageThumbnail: variation.itemImageThumbnail?.[0],
                        variationId: variation.design_variation_unique_id
                      });
                    });
                  }
                }
              }
              let isrequired = false;
              if( isExistLocations?.[0]?.artworkID == ( artwork?.designId || artwork.id ) && isExistLocations?.[0]?.isRequiredForStore == "1" ) {
                isrequired = true;
              }
              let customeIndex = 0
              let selectedArtWrk = selectedDecorationsList?.length && selectedDecorationsList.find(res => res.location === locationName && (productDesignId === artwork?.designId || productDesignId === artwork?.id))
              let selectedIndex = selectedDecorationsList?.length && selectedDecorationsList?.findIndex(res => res.location === locationName && (productDesignId === artwork?.designId || productDesignId === artwork?.id));
              if( imgUrls.length > 0 ) {
                return (
                  <div className={ `${ isrequired ? "requiredDeco" : "" }` } key={ 'art2' + index }>
                    { ( productDesignId === artwork?.designId || productDesignId === artwork?.id ) &&
                        imgUrls.map(( variation, imgIndex ) => (
                          <div
                            className={ 'col-md artworkbox artworkbox-selected decorows_selartwork' }
                            key={ index + '_' + imgIndex + variation.variationId }
                          >
                            <img
                              key={ imgIndex + variation.variationId }
                              src={ replaceOrigin(variation.itemImageThumbnail) }
                              className="img-fluid mt-2"
                              alt="No Image"
                              style={ { maxHeight: '30px' } }
                              onClick={ () => {
                                handleArtworkselection(artwork, variation);
                              } }
                            />
                            { ( selectedArtWrk && ( !selectedArtWrk?.isRequiredForStore || selectedArtWrk?.isRequiredForStore == "0" ) ) ?
                              <div style={ {
                                bottom: "5px",
                                right: " 5px",
                                position: "absolute",
                              } }>
                                <DeleteIcon
                                  className="float-right"
                                  onClick={ () => {
                                    showPopupText([], [], selectedIndex)
                                  } }
                                /></div> : ''
                            }
                          </div>
                        ))
                    }
                  </div>
                );
              }
            }
          }) }
        </div>
      ) : (
        <div className="location-hats-data mb-10">
          { tempArtworkListById.length > 0 && showArtwork && tempArtworkListById.map(( artwork, index ) => {
            let showAllArtworkVariations = artworksFromStore ? showAllVariation : false;
            let imgUrls = [];
            let displayArtwork = true;
            let getCustomArtwotkByUser = true;

            let storeItem = storeArtwork.filter(item => item.designId == artwork?.designId);
            getCustomArtwotkByUser = ( storeItem?.[0]?.userId === user.userId );
            let checkLocation = artwork.productToDecorationMapping.filter(val => val.location == selectedProductLocation);
            if( ArtworkDuplicatesAllowed === false ) {
              let isExist = selectedDecorationsList.filter(decoration => decoration.artworkID == artwork.id);
              if( selectedDecorationsList.length > 0 && isExist.length > 0 ) {
                displayArtwork = false;
              }
            }

            let isExistLocations = null;
            if( selectedDecorationsList?.length > 0 ) {
              isExistLocations = selectedDecorationsList.filter(decoration => (
                ( decoration?.location === artwork?.artworkLocation ||
                  artwork.productToDecorationMapping.some(res => res.locationName === decoration.location) ) &&
                decoration?.isRequiredForStore === '1'
              ));
            }
            if( displayArtwork === true && getStatusLocation(artwork.id) ) {
              if( checkLocation.length >= 1 && checkLocation?.[checkLocation.length - 1]?.showAllVariations === '0') {
                let getVariationId = checkLocation[checkLocation.length - 1].mapping.filter(val => val.color === selectedProductColor);
                if( getVariationId.length > 0 ) {
                  getVariationId.forEach(variation => {
                    let imageUrl = artwork.design.variation.find(val => val.design_variation_unique_id === variation.variationId);
                    if( imageUrl ) {
                      imgUrls.push({
                        itemImageThumbnail: imageUrl.itemImageThumbnail?.[0],
                        variationId: variation.variationId,
                      });
                    }
                  });
                }
              } else {
                if( checkLocation?.[checkLocation.length - 1] ) {
                  showAllArtworkVariations = checkLocation[checkLocation.length - 1].showAllVariations === '1';
                } else {
                  showAllArtworkVariations = showAllVariation; //drop here for show all variations from local setting
                }
                const variationsToShow =  artwork.design.variation.filter(res => {
                  const variationColor = typeof res.design_variation_color === 'string' ? res.design_variation_color : '';
                  if((variationColor === '' || variationColor.split(',')
                      .map(word => word.toLowerCase().trim())
                      .includes(selectedProductColor.toLowerCase()) || showAllArtworkVariations )
                    && !!res?.itemImageThumbnail?.[0] ) {
                    return res;
                  }
                });
                if( ( !( !!artwork?.artworkLocation ) || artwork?.artworkLocation === locationName ) &&
                  artwork?.design?.variation != "" && (artwork.productToDecorationMapping == "" || showAllArtworkVariations) ) {
                  if (variationsToShow && variationsToShow.length > 0) {
                    variationsToShow.forEach(variation => {
                      if (storeItem && storeItem.length > 0 && artworkGlobalIndicator) {
                        if (storeItem[0].isEnable
                          && (getCustomArtwotkByUser
                            || artworkSetting
                            || allowCustomDecoration
                            || artwork.artworkSource !== 'aether'
                            && !storeItem[0].userId)) {
                          imgUrls.push({
                            itemImageThumbnail: variation.itemImageThumbnail?.[0],
                            variationId: variation.design_variation_unique_id,
                          });
                        }
                      } else if (artwork?.statusId === "5") {
                        imgUrls.push({
                          itemImageThumbnail: variation.itemImageThumbnail?.[0],
                          variationId: variation.design_variation_unique_id,
                        });
                      }
                    });
                  } else if( storeItem && storeItem.length > 0 && artworkGlobalIndicator && artwork?.design?.variation?.length > 0 ) {
                    if( storeItem[0].isEnable
                      && ( getCustomArtwotkByUser
                        || artworkSetting
                        || allowCustomDecoration
                        || ( artwork.artworkSource !== 'aether' && !storeItem[0].userId ) ) ) {
                      variationsToShow.forEach(variation => {
                        imgUrls.push({
                          itemImageThumbnail: variation.itemImageThumbnail?.[0],
                          variationId: variation.design_variation_unique_id
                        });
                      });
                    }
                  } else if( artwork?.statusId === "5" ) {
                    variationsToShow.forEach(variation => {
                      imgUrls.push({
                        itemImageThumbnail: variation.itemImageThumbnail?.[0],
                        variationId: variation.design_variation_unique_id
                      });
                    });
                  }
                }
              }

              let isrequired = false;
              if( isExistLocations?.[0]?.artworkID == ( artwork.id || artwork.designId ) && isExistLocations?.[0]?.isRequiredForStore == "1" ) {
                isrequired = true;
              }
              let selectedArtWrk = selectedDecorationsList?.length && selectedDecorationsList.find(res => res.location === locationName && res.artworkID === (artwork.id || artwork.designId))
              let selectedIndex = selectedDecorationsList?.length && selectedDecorationsList?.findIndex(res => res.location === locationName && res.artworkID === (artwork.id || artwork.designId));
              let customeIndex = 0;
              if( imgUrls.length > 0 ) {
                return imgUrls.map(( variation, imgIndex ) => (
                  <div className={ `${ isrequired ? "artMain requiredDeco" : "artMain" }` }
                       key={ 'artew_' + index + '_' + imgIndex }>
                    <div
                      className={ 'col-md artworkbox artworkbox-selected decorows_selartwork' }
                      key={ index + '_' + imgIndex + variation.variationId }
                    >
                      <img
                        id={ 'artew_' + customeIndex + variation.variationId }
                        src={ replaceOrigin(variation.itemImageThumbnail) }
                        onClick={ () => {
                          handleArtworkselection(artwork, variation);
                        } }
                        className="img-fluid mt-3"
                        alt="No Image"
                        style={ { maxHeight: '30px' } }
                      />
                      {(selectedArtWrk && (!selectedArtWrk?.isRequiredForStore || selectedArtWrk?.isRequiredForStore == "0")) ?
                        <div style={{
                          bottom: "5px",
                          right: " 5px",
                          position: "absolute",
                        }}>
                          <DeleteIcon
                            className="float-right"
                            onClick={() => {
                              showPopupText([], [], selectedIndex)
                            }}
                          /></div> : ''
                      }
                    </div>
                  </div>
                ));
              }
            }
          }) }
        </div>
      ) }
    </div>
  );
}
const mapDispatchToProps = dispatch => {
  return {};
};
const mapStateToProps = state => {
  return {
    artworkList: state.productData.artworkList,
    artworkListById: state.productData.artworkListById,
    artworkCategoriesRestriction: state.productData.artworkCategoriesRestriction,
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(AddDecorationHats);
import {BasicUser, BasicUserSearchReq, MultipleBasicUserReq, UserCostCenterResponse} from '../../models/User';
import { aetherApi } from './aetherApi';

const userApi = aetherApi.injectEndpoints({
  endpoints: (builder) => ({
    getUserCostCenters: builder.query<UserCostCenterResponse, void>({
      query: () => `api/v1/user/cost-centers/all`,
    }),
    getUserGroupAccountId: builder.query<{ accountId: string }, string>({
      query: (userId) => `api/v1/user/${userId}/user-group-account`,
    }),
    getMultipleBasicUsers: builder.query<
      BasicUser[],
      MultipleBasicUserReq
    >({
      query: ({
                ids
              }) => ({
        url: 'api/v1/user/basic/multiple',
        method: 'POST',
        body: { ids },
      }),
    }),
    searchBasicUsers: builder.query<BasicUser[], BasicUserSearchReq>({
      query: ({ query, limit = 10 }) => ({
        url: 'api/v1/user/basic/search',
        params: { query, limit },
      }),
    }),
  }),
});

export const {
  useGetUserCostCentersQuery,
  useGetUserGroupAccountIdQuery,
  useSearchBasicUsersQuery,
  useGetMultipleBasicUsersQuery,
} = userApi;

import React from 'react';
import {
  Divider,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Radio,
  Stack,
  Typography,
} from '@mui/material';
import { FulfillmentQuoteMethod } from '../../../models/FulfillmentQuote';
import { useAppSelector } from '../../../redux/hooks';
import { selectDebug } from '../../../redux/selectors/checkoutSelectors';
import { getAddressString } from '../../../helpers/checkout';
import useCurrencyDisplay from '../useCurrencyDisplay';

export interface FulfillmentQuoteMethodListProps {
  methods: FulfillmentQuoteMethod[];
  selectedMethodId: string | null;
  onSelectMethod: (method: FulfillmentQuoteMethod) => void;
}

export default function FulfillmentQuoteMethodList({
  methods,
  selectedMethodId,
  onSelectMethod,
}: FulfillmentQuoteMethodListProps) {
  const { format } = useCurrencyDisplay();
  const debug = useAppSelector(selectDebug);

  const handleSelect = (method: FulfillmentQuoteMethod) => () => {
    onSelectMethod(method);
  };

  return (
    <List disablePadding>
      {methods.map((method, idx) => (
        <React.Fragment key={idx}>
          <ListItem
            key={method.methodId}
            data-testid={`method-${method.methodId}`}
            secondaryAction={
              method.amount === 0 && method.rateMode?.toLowerCase()?.trim() === 'free' ? (
                <Typography variant="body1">Free</Typography>
              ) : (
                <Typography variant="body1">{format(method.amount)}</Typography>
              )
            }
            disablePadding
          >
            <ListItemButton role={undefined} onClick={handleSelect(method)} dense>
              <ListItemIcon>
                <Radio
                  checked={selectedMethodId === method.methodId}
                  tabIndex={-1}
                  disableRipple
                  inputProps={{ 'aria-labelledby': method.name }}
                />
              </ListItemIcon>
              <Stack direction={'column'}>
                <ListItemText primary={method.name} primaryTypographyProps={{ variant: 'body1' }} />
                {method.address && (
                  <ListItemText primary={'Address'} secondary={getAddressString(method.address)} />
                )}
                {method.instructions && selectedMethodId === method.methodId && (
                  <ListItemText primary={'Instructions'} secondary={method.instructions} />
                )}
                {debug && method.fallback && <ListItemText primary={'Fallback'} />}
                {debug && <ListItemText primary={`Package Count: ${method.packageCount}`} />}
                {debug && (
                  <ListItemText
                    primary={`Breakups: ${method.amountBreakups?.map((b) => format(b)).join(', ')}`}
                  />
                )}
              </Stack>
            </ListItemButton>
          </ListItem>
          <Divider />
        </React.Fragment>
      ))}
    </List>
  );
}

import { createTheme, keyframes } from '@mui/material'
import type {} from '@mui/lab/themeAugmentation'

export interface StoreStyleOptions {
  themedefaultColor: string
  themedefaultTextColor: string
  fontFamily?: string
}

const progressAnimation = keyframes`
  0% {
    stroke-dasharray: 1px, 200px;
    stroke-dashoffset: 0px;
  }
  50% {
    stroke-dasharray: 100px, 200px;
    stroke-dashoffset: -15px;
  }
  100% {
    stroke-dasharray: 100px, 200px;
    stroke-dashoffset: -122px;
  }
`

const baseTheme = (options: StoreStyleOptions) =>
  createTheme({
    palette: {
      primary: {
        main: options.themedefaultColor,
      },
    },
    spacing: [4, 8, 16, 24, 32, 40, 48, 56, 64],
    typography: {
      fontFamily: options.fontFamily,
    }
  })

export const newTheme = (options: StoreStyleOptions) => {
  const theme = baseTheme(options)
  return createTheme(theme, {
    typography: {
      fontFamily: 'inherit',
      lineCaption: {
        lineHeight: 1.3,
        fontSize: '0.75rem',
        fontWeight: 400,
      },
    },
    components: {
      MuiTablePagination: {
        styleOverrides: {
          selectLabel: {
            marginTop: '1rem !important',
          },
          displayedRows: {
            marginTop: '1rem !important',
          },
        },
      },
      MuiInputBase: {
        styleOverrides: {
          inputMultiline: {
            border: 'none !important',
          },
          root: {
            borderRadius: '8px !important',
          },
        },
      },
      MuiCircularProgress: {
        styleOverrides: {
          circle: {
            strokeLinecap: 'round',
            animationName: progressAnimation,
          },
        },
      },
      MuiTypography: {
        defaultProps: {
          variantMapping: {
            lineCaption: 'span',
          },
        },
      },
      MuiCard: {
        styleOverrides: {
          root: {
            borderRadius: '12px',
          },
        },
        defaultProps: {
          variant: 'outlined',
        },
      },
      MuiPaper: {
        styleOverrides: {
          root: {
            borderRadius: '12px',
          },
        },
      },
      MuiSkeleton: {
        defaultProps: {
          animation: 'wave',
          variant: 'rounded',
        },
        styleOverrides: {
          root: {
            variants: [
              {
                props: { variant: 'rounded' },
                style: {
                  borderRadius: '12px',
                },
              },
            ],
          },
        },
      },
      MuiButton: {
        styleOverrides: {
          root: {
            borderRadius: '24px',
          },
        },
        variants: [
          {
            props: { size: 'large' },
            style: {
              fontSize: '1rem',
            },
          },
        ],
      },
      MuiContainer: {
        styleOverrides: {
          root: {
            marginTop: theme.spacing(6),
            marginBottom: theme.spacing(6),
            [theme.breakpoints.up('xs')]: {
              paddingLeft: theme.spacing(1),
              paddingRight: theme.spacing(1),
            },
            [theme.breakpoints.up('sm')]: {
              paddingLeft: theme.spacing(3),
              paddingRight: theme.spacing(3),
            }
          },
        },
      },
    },
  })
}

import PropTypes from "prop-types";
import React, { useContext, useEffect, useState } from "react";
import useElementSize from "../../util/custom-hooks/useElementSize";
import { productImageExt, productImageUrl } from "../../util/helper";
import { LightgalleryItem, LightgalleryProvider } from "react-lightgallery";
import { AuthContext } from '../../common/Auth'
import { useProductConfigState } from "../../pages/shop-product/state/useProductConfigState";
import { fetchImageSize, getImageJustification } from "../../wrappers/product/util/getDimensions";

const ProductImageGalleryStepper = ( {
                                       product,
                                       selectedColor,
                                       setSelectedColor,
                                       selectedDecorations,
                                       artworkListById,
                                       artworkList,
                                       selectedImage,
                                       singleSelectedDecorations,
                                       setSelectedImage,
                                       isReplace,
                                       isModel = false,
                                     } ) => {

  artworkListById = artworkListById?.concat(artworkList?.filter(al => artworkListById?.every(ali => ali?.id != al?.id)));
  const [ DefaultProductBackgroundColor, setDefaultProductBackgroundColor ] = useState()
  const [ imgW, imgH ] = useElementSize('.isactive .product-sticky-image__single')
  const [ imageW, setImageW ] = useState(imgW)
  const [ imageH, setImageH ] = useState(imgH)
  const [ imageChanged, setImageChanged ] = useState(0)
  let tempData = [];
  const [ overlayImgWidth, setOverlayImgWidth ] = useState(0);
  const [ overlayImgHeight, setOverlayImgHeight ] = useState(0);
  const [ overlayImgTop, setOverlayImgTop ] = useState(0);
  const [ overlayImgLeft, setOverlayImgLeft ] = useState(0);
  useEffect(() => {
    let temp = product?.WebstoreProductPartArray.filter(( val ) => val.image === selectedImage && val?.isSwapImage,)
    if( temp.length > 0 ) {
      if( product?.WebstoreProductPartArray ) {
        product.WebstoreProductPartArray.map(( single, key ) => {
          if( !!selectedImage && single.image === selectedImage ) {
            tempData[`myRefRow${ key }`].current.scrollIntoView();
          }
        })
      }
    } else {
      product.MediaContent.map(( single, key ) => {
        if( !!selectedImage && single.url === selectedImage ) {
          tempData[`myRefRow${ key }`].current.scrollIntoView();
        }
      })
    }
  }, [ selectedImage ])

  const {
    getSettingStatus,
  } = useProductConfigState({ product })

  useEffect(() => {
    setTimeout(() => {
      const element = document.querySelectorAll('.isactive .product-sticky-image__single');
      setImageW(element[0]?.offsetWidth)
      setImageH(element[0]?.offsetHeight)
    }, 1000);
  }, [ selectedDecorations ]);

  useEffect(() => {
    setTimeout(() => {
      const element = document.querySelectorAll('.isactive .product-sticky-image__single');
      setImageW(element[0]?.offsetWidth)
      setImageH(element[0]?.offsetHeight)
    }, 500);
  }, [ imgW, imgH ]);


  useEffect(() => {
    setTimeout(() => {
      const element = document.querySelectorAll('.containerimg .isactive .product-sticky-image__single');
      if( element.length > 0 ) {
        setImageW(element[0]?.offsetWidth)
        setImageH(element[0]?.offsetHeight)
        if( element[0]?.offsetWidth <= 451 && element[0]?.offsetWidth > 0 ) {
          const element1 = document.querySelectorAll('.isactive');
          if( element1.length > 0 ) {
            element1[0].style.width = `${ element[0]?.offsetWidth }px`
          }
        }
      }
    }, 100);
  }, [ imageChanged ]);

  useEffect(() => {
    if( singleSelectedDecorations ) {
      let getIndex = product.LogoInfo.filter(
        ( val ) =>
          val.color === selectedColor &&
          val.location === singleSelectedDecorations.location
      );

      if( getIndex.length > 0 ) {
        setSelectedImage(getIndex[0]?.image);
        setImageChanged(imageChanged + 1)
      } else {
        let getIndexOfExtra_logo = [];
        product.LogoInfo.map(( val ) => {
          if( val.color === selectedColor ) {
            if(
              val.extra_logo.filter(
                ( e ) => e.location === singleSelectedDecorations.location
              ).length > 0
            ) {
              getIndexOfExtra_logo.push(val);
            }
          }
        });
        if( getIndexOfExtra_logo.length ) {
          setSelectedImage(getIndexOfExtra_logo[0]?.image);
          setImageChanged(imageChanged + 1)
        } else {
          let getImgColorIndex = product.LogoInfo.filter(
            ( val ) => val.color === selectedColor
          );

          if( getImgColorIndex.length > 0 ) {
            setSelectedImage(getImgColorIndex[0]?.image);
            setImageChanged(imageChanged + 1)
          }
        }
      }
    }
  }, [ singleSelectedDecorations ]);

  const {
    settingData
  } = useContext(AuthContext)

  useEffect(() => {
    if( settingData?.length > 0 ) {
      settingData.map(( i ) => {
        if( i.page === 'General' ) {
          if( !!i?.values?.DefaultProductBackgroundColor ) {
            setDefaultProductBackgroundColor(i?.values?.DefaultProductBackgroundColor);
          }
        }
      })
    }
  }, [ settingData ])

  let getSingleImage = {}
  const photoZoom = async( zmImg = '', singleLogoDetails = {}, key = '' ) => {
    let node = document.querySelectorAll(".single_image")[key]
    if( isModel ) {
      node = document.querySelectorAll(".modal-body .single_image")[key]
    }
    let zoomPictureNode = document.getElementsByClassName("lg-img-wrap")
    if( !!zoomPictureNode && !!zoomPictureNode?.[0] && !!DefaultProductBackgroundColor && zmImg !== '' ) {
      zoomPictureNode[0].classList.add(zmImg)
    }

    if( node ) {
      const clone = node.cloneNode(true);
      zoomPictureNode[0].classList.add('lg-img-zoom')
      if( zoomPictureNode ) {
        zoomPictureNode[0].appendChild(clone);
      }

      var fourChildNode = zoomPictureNode[0].querySelector('.isactive');
      if( !!fourChildNode ) {
        fourChildNode.style.maxWidth = 'unset'
        fourChildNode.style.width = 'unset'
        fourChildNode.style.margin = 'unset'
      }
      let fourChildNode0 = zoomPictureNode[0].querySelector('.containerimg .react_lightgallery_item');
      let fourChildNode1 = zoomPictureNode[0].querySelector('.containerimg .react_lightgallery_item .img-fluid');
      if( !!fourChildNode1 && fourChildNode1?.width > 2000 ) {
        fourChildNode1.style.height = 'auto'
        fourChildNode0.style.display = 'flex'
        fourChildNode0.style.alignItems = 'center'
        fourChildNode0.style.justifyContent = 'center'
      }
    }

    getSingleImage = singleLogoDetails
    let getOuterBOx = document.querySelector('.lg-img-wrap .react_lightgallery_item')
    if( !!getOuterBOx && !!getSingleImage ) {
      let imgWidthZoom = parseFloat(getSingleImage?.iw) || 0;
      let imgHeightZoom = parseFloat(getSingleImage?.ih) || 0;
      let singleZoomX = parseFloat(getSingleImage?.x) || 0;
      let singleZoomY = parseFloat(getSingleImage?.y) || 0;
      let singleZoomW = parseFloat(getSingleImage?.w) || 0;
      let singleZoomH = parseFloat(getSingleImage?.h) || 0;

      if( getSingleImage?.isNewLogo === "1" && !!getSingleImage?.processedImage ) {
        imgWidthZoom = getSingleImage?.processedImageWidth
        imgHeightZoom = getSingleImage?.processedImageHeight
        singleZoomX = getSingleImage?.newX
        singleZoomY = getSingleImage?.newY
        singleZoomW = getSingleImage?.newW
        singleZoomH = getSingleImage?.newH
      }
      const { width, height } = await fetchImageSize(getSingleImage.image);
      const imageJustify = getImageJustification(getSingleImage);
      let maxValue = Number.MIN_VALUE;
      let ratio = 1;
      let newX = getSingleImage.x;
      let newY = getSingleImage.y;
      let newW = getSingleImage.w;
      let newH = getSingleImage.h;

      let newIw = width, newIh = height;
      if( height > 800 || width > 800 ) {

        maxValue = Math.max(width, height);
        if( maxValue === height ) {
          ratio = 800 / height
          newIh = 800;
          newIw = ratio * width;
        } else {
          ratio = 800 / width;
          newIw = 800;
          newIh = height * ratio;
        }
      }
      if( ratio !== 1 ) {
        newW = ( getSingleImage.w * 100 ) / newIw;
        newX = ( getSingleImage.x * 100 ) / newIw;
        newY = ( getSingleImage.y * 100 ) / newIh;
        newH = ( getSingleImage.h * 100 ) / newIh;
      } else {
        newW = ( getSingleImage.w * 100 ) / width;
        newX = ( getSingleImage.x * 100 ) / width;
        newY = ( getSingleImage.y * 100 ) / height;
        newH = ( getSingleImage.h * 100 ) / height;
      }

      let overlayImag = document.querySelectorAll(".lg-img-wrap .overlayimg")
      const css = '.overlayimg:hover{  transform: scale(1.5); }';
      const style = document.createElement('style');
      if( style.sheet ) {
        style.sheet.cssText = css;
      } else {
        style.appendChild(document.createTextNode(css));
      }
      for( let i = 0; i < overlayImag.length; i++ ) {
        overlayImag[i].style.left = newX + "%"
        overlayImag[i].style.top = newY + "%"
        overlayImag[i].style.width = newW + "%"
        overlayImag[i].style.height = newH + "%"
        overlayImag[i].style.transition = 'transform 0.3s';
        setOverlayImgTop(newX)
        setOverlayImgLeft(newX)
        setOverlayImgWidth(newW);
        setOverlayImgHeight(newH);
        overlayImag[i].appendChild(style)
      }

      let overlayImag2 = document.querySelectorAll(".lg-img-wrap .overlayimg .img-fluid")
      for( let i = 0; i < overlayImag2.length; i++ ) {
        overlayImag2[i].style.removeProperty('max-width');
        overlayImag2[i].style.removeProperty('max-height');
        overlayImag2[i].style.left = overlayImgLeft;
        overlayImag2[i].style.top = overlayImgTop;
        overlayImag2[i].style.width = '100%';
        overlayImag2[i].style.height = '100%';
        overlayImag2[i].style.objectFit = 'contain';
        overlayImag2[i].style.objectPosition = imageJustify
      }
    }
    let getOuterBOxCheck2 = document.querySelector('.lg-img-wrap .lightGal')

    if( !getOuterBOxCheck2 ) {
      return
    }
    let zoomInBtn = document.getElementById('lg-zoom-in');
    let zoomOutBtn = document.getElementById('lg-zoom-out');

    let scaleItem = 1

    zoomInBtn.addEventListener('click', function( event ) {
      getOuterBOxCheck2.style.transform = `scale(${ scaleItem + 0.5 }) translateX(-50%)`
      scaleItem = scaleItem + 0.5
    });
    zoomOutBtn.addEventListener('click', function( event ) {
      getOuterBOxCheck2.style.transform = `scale(${ scaleItem - 0.5 }) translateX(-50%)`
      scaleItem = scaleItem - 0.5
    });
  }

  const getReducePer = ( width, param ) => {
    let reducePer = 100;
    while( ( ( Number(width) * reducePer ) / 100 ) > param ) {
      reducePer = reducePer - 1;
    }
    return reducePer;
  }

  return (
    <div className="product-large-image-wrapper product-large-image-wrapper ">
      { product.discount || product.new ? (
        <div className="product-img-badges">
          { product.discount ? (
            <span className="pink">-{ product.discount }%</span>
          ) : (
            ""
          ) }
          { product.new ? <span className="blue">New</span> : "" }
        </div>
      ) : (
        ""
      ) }
      <div className="product-sticky-image mb--10">
        { isReplace ? product && product.MediaContent &&
          product.MediaContent.map(( single, key ) => {
            let prImage = single.url
            if( single?.isNewLogo == "1" && !!single?.processedImage ) {
              prImage = single.processedImage
            }
            let getImage = product?.WebstoreProductPartArray.filter(( val ) => val.color == single.color && val.isSwapImage == true,)
            if( getImage.length > 0 ) {
              prImage = getImage[0].image
            }
            let ref = `myRefRow${ key }`
            tempData[ref] = React.createRef()
            let findLogoInfo = ''
            let singleLogoDetails = {}

            if( getSettingStatus('showDecoration') && singleSelectedDecorations != "" && singleSelectedDecorations != null && singleSelectedDecorations != "null" && singleSelectedDecorations != undefined ) {
              for( let i = 0; i < product?.LogoInfo.length; i++ ) {
                const logoInfoData = product?.LogoInfo[i]
                if( logoInfoData.color === selectedColor ) {
                  if( logoInfoData.image === selectedImage && logoInfoData.location == singleSelectedDecorations.location ) {
                    findLogoInfo = logoInfoData
                    break;
                  } else if( logoInfoData.location == singleSelectedDecorations.location ) {
                    findLogoInfo = logoInfoData
                    break;
                  } else if( logoInfoData.image === selectedImage ) {
                    let extra_logo = logoInfoData.extra_logo.filter(val => val.location == singleSelectedDecorations.location)

                    if( extra_logo.length > 0 ) {

                      findLogoInfo = extra_logo
                      findLogoInfo[0].image = logoInfoData.image
                      findLogoInfo[0].color = logoInfoData.color
                      findLogoInfo[0].ih = logoInfoData.ih
                      findLogoInfo[0].iw = logoInfoData.iw
                      findLogoInfo[0].location = extra_logo[0].location
                      findLogoInfo[0].h = extra_logo[0].h

                      findLogoInfo[0].w = extra_logo[0].w
                      findLogoInfo[0].x = extra_logo[0].x
                      findLogoInfo[0].y = extra_logo[0].y
                      if( logoInfoData?.isNewLogo == "1" && !!logoInfoData?.processedImage ) {
                        findLogoInfo[0].processedImageWidth = logoInfoData?.processedImageWidth
                        findLogoInfo[0].processedImageHeight = logoInfoData?.processedImageHeight
                        findLogoInfo[0].isNewLogo = logoInfoData?.isNewLogo
                        findLogoInfo[0].processedImage = logoInfoData?.processedImage
                      }
                      break;
                    }
                  }
                }
              }
            }

            if( findLogoInfo !== '' && findLogoInfo?.length ) {
              singleLogoDetails = findLogoInfo[0]
            } else if( findLogoInfo !== '' ) {
              singleLogoDetails = findLogoInfo
            }

            let imgWidth = singleLogoDetails.iw
            let imgHeight = singleLogoDetails.ih
            let singleX = singleLogoDetails?.x
            let singleY = singleLogoDetails?.y
            let singleW = singleLogoDetails?.w
            let singleH = singleLogoDetails?.h
            if( singleLogoDetails?.isNewLogo == "1" && !!singleLogoDetails?.processedImage ) {
              imgWidth = singleLogoDetails?.processedImageWidth
              imgHeight = singleLogoDetails?.processedImageHeight
              singleX = singleLogoDetails?.newX
              singleY = singleLogoDetails?.newY
              singleW = singleLogoDetails?.newW
              singleH = singleLogoDetails?.newH
              prImage = singleLogoDetails?.processedImage
            }

            if( imgWidth > 900 || imgHeight > 1100 ) {
              var ratio = Math.min(800 / imgWidth, 800 / imgHeight);

              imgWidth = imgWidth * ratio
              imgHeight = imgHeight * ratio
            }

            let reducePer = getReducePer(imgHeight, imageH)

            //artwork box left top position set set
            let positionSetLeft = ( imageW * singleX ) / imgWidth
            let positionSetTop = ( imageH * singleY ) / imgHeight
            const imageJustifyProduct = getImageJustification(getSingleImage)
            //artwork box width/height set
            let positionSetBoxWidth = ( imageW * singleW ) / imgWidth
            let positionSetBoxHeight = ( imageH * singleH ) / imgHeight
            let mainCls = ''
            if( ( getImage && getImage.length > 0 && !!prImage && productImageExt(prImage) ) || productImageExt(process.env.PUBLIC_URL + prImage) ) {
              mainCls = 'product-img-bg'
            }
            let isactive = false
            if( getImage?.[0]?.image === selectedImage && selectedImage != "" ) {
              isactive = true
            } else if( single?.url === selectedImage && selectedImage != "" ) {
              isactive = true
            }
            return (
              <div className={ `single_image lightGal ${ mainCls }` } key={ key }>
                <div className={ `containerimg ${ isactive === true ? 'isactive' : '' }` }
                     style={ { maxWidth: "451px", margin: "0px auto" } }>
                  <div className={ `product-sticky-image__single mb-10` } key={ key } ref={ tempData[ref] }>
                    <LightgalleryProvider onAfterOpen={ () => photoZoom(mainCls, singleLogoDetails, key) }>
                      <LightgalleryItem
                        group="any"
                        src={ getImage && getImage.length > 0 ? productImageUrl(getImage[0].image) : productImageUrl(
                          process.env.PUBLIC_URL + single.url,
                        ) }
                      >
                        <img
                          src={ getImage && getImage.length > 0 ? productImageUrl(prImage) : productImageUrl(process.env.PUBLIC_URL + prImage) }
                          alt=""
                          className="img-fluid"
                          style={ {
                            transitionDelay: 'none !important',
                            top: positionSetTop + 'px',
                            left: positionSetLeft + 'px',
                          } }
                        />
                        { singleLogoDetails && getSettingStatus('showDecoration') &&
                          Object.keys(singleLogoDetails).length > 0 && selectedDecorations != "" &&
                          selectedImage === single.url && selectedDecorations != null && artworkListById?.length > 0 &&
                          selectedDecorations.length > 0 && selectedDecorations.map(( li, key ) => {
                          let artwork_selected = null
                          if( li?.artworkID !== '' && li?.artworkID !== undefined ) {

                            const idToSearch = singleSelectedDecorations ? singleSelectedDecorations.artworkID : li.artworkID
                            artwork_selected = artworkListById?.find(
                              ( artworkArray ) => artworkArray.id === idToSearch,
                            )
                          }

                          let rowVariation = null
                          if( artwork_selected?.design?.variation?.length > 0 ) {
                            rowVariation = artwork_selected.design.variation.find(
                              ( variationArray ) =>
                                variationArray.design_variation_unique_id ===
                                li.variationUniqID,
                            )
                          }
                          if( !!rowVariation && li.location === singleLogoDetails.location) {
                            return <div className="overlayimg" key={ key }
                                        style={ {
                                          transitionDelay: 'none !important',
                                          top: positionSetTop + 'px',
                                          left: positionSetLeft + 'px',
                                          width: '100%',
                                          height: '100%',
                                        } }
                            >
                              {
                                rowVariation && rowVariation?.itemImageThumbnail && rowVariation.itemImageThumbnail[0] ?
                                  <img
                                    className="img-fluid"
                                    alt=""
                                    src={
                                      rowVariation?.itemImageThumbnail
                                        ? productImageUrl(
                                          rowVariation.itemImageThumbnail[0],
                                        )
                                        : ''
                                    }

                                    style={ {
                                      transitionDelay: 'none !important',
                                      maxWidth: positionSetBoxWidth + 'px',
                                      maxHeight: positionSetBoxHeight + 'px',
                                      top: positionSetTop + 'px',
                                      left: positionSetLeft + 'px',
                                      objectPosition: imageJustifyProduct,
                                      width: '100%',
                                      height: '100%',
                                    } }
                                  />
                                  :
                                  <img className="img-fluid"
                                       src={ rowVariation && rowVariation?.itemImageThumbnail != "" ? productImageUrl(rowVariation) : productImageUrl(rowVariation) }
                                       alt=""

                                       style={ {
                                         transitionDelay: 'none !important',
                                         maxWidth: positionSetBoxWidth + 'px',
                                         maxHeight: positionSetBoxHeight + 'px',
                                         top: positionSetTop + 'px',
                                         left: positionSetLeft + 'px',
                                         objectPosition: imageJustifyProduct,
                                         width: '100%',
                                         height: '100%',
                                       } }
                                  />
                              }
                            </div>
                          }
                        }) }
                      </LightgalleryItem>
                    </LightgalleryProvider>
                  </div>
                </div>
              </div>
            );
          }) :
          product && product.WebstoreProductPartArray &&
          product?.WebstoreProductPartArray?.filter(
            ( ele, ind ) => ind === product?.WebstoreProductPartArray.findIndex(elem => elem.color === ele.color)).map(( single, key ) => {
            let getImage = product?.WebstoreProductPartArray.filter(( val ) => val.color == single.color && val.isSwapImage == true,)
            let mediaImage = product?.MediaContent?.filter(
              ( mediaArray ) => !mediaArray.hidden && mediaArray.color == single.color)
            let ref = `myRefRow${ key }`
            tempData[ref] = React.createRef()
            let mainCls = ''
            if( ( ( getImage && getImage.length > 0 && !!getImage[0]?.image && productImageExt(getImage[0]?.image) ) || productImageExt(process.env.PUBLIC_URL + single.url) ) || productImageExt(process.env.PUBLIC_URL + mediaImage[0]?.url) ) {
              mainCls = 'product-img-bg'
            }
            let isactive = false
            if( getImage?.[0]?.image === selectedImage && selectedImage != "" ) {
              isactive = true
            } else if( single?.url === selectedImage && selectedImage != "" ) {
              isactive = true
            }
            return (
              <div className={ `single_image lightGal ${ mainCls }` } key={ key }>
                <div className={ `containerimg ${ isactive === true ? 'isactive' : '' }` }
                     style={ { maxWidth: "451px", margin: "0px auto" } }>
                  <div className="product-sticky-image__single mb-10" key={ key } ref={ tempData[ref] }>
                    <LightgalleryProvider onAfterOpen={ () => photoZoom(mainCls, {}, key) }>
                      <LightgalleryItem
                        group="any"
                        src={ getImage && getImage.length > 0 ? productImageUrl(getImage[0].image) : productImageUrl(
                          process.env.PUBLIC_URL + single.url,
                        ) }
                      >
                        <img
                          src={ getImage && getImage.length > 0 ? productImageUrl(getImage[0].image) : single.image ? productImageUrl(process.env.PUBLIC_URL + single.image) : productImageUrl(
                            process.env.PUBLIC_URL + mediaImage[0]?.url,
                          ) }
                          alt=""
                          className="img-fluid"
                          style={ { transitionDelay: 'none !important' } }
                        />
                      </LightgalleryItem>
                    </LightgalleryProvider>
                  </div>
                </div>
              </div>
            );
          }) }

      </div>
    </div>
  );
}

ProductImageGalleryStepper.propTypes = {
  product: PropTypes.object
};

export default ProductImageGalleryStepper;
